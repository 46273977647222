import { useEffect, useState } from 'react';
import NotificationBar from '../../shared/ui-components/NotificationBar/NotificationBar';
import LoadingSpinner from '../../shared/ui-components/LoadingSpinner/LoadingSpinner';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectNextRoute, setCurrentWorkflowStep, WorkflowStep } from '../../store/Workflow.store';
import { getRechnungsempfaenger } from '../../store/Rechnungsempfaenger.store';
import styles from './RechnungsempfaengerAuswaehlen.module.scss';
import { Rechnungsempfaenger } from '../../shared/types';
import SelectableCard from '../../shared/ui-components/SelectableCard/SelectableCard';
import { setBestellpositionen, setRechnungsempfaenger } from '../../store/Bestellung.store';
import { showConfirmationDialog } from '../../shared/ui-components/ConfirmationDialog/confirmationDialog';
import type { RootState } from '../../configureStore';
import WorkflowButtons from '../../shared/content-components/WorkflowButtons/WorkflowButtons';
import useDocumentTitle from '../../shared/hooks/useDocumentTitle';
import { selectIsDebitor, selectIsFarmleiter } from '../../store/Navigation.store';

const RechnungsempfaengerAuswaehlen = (): JSX.Element => {
    const history = useHistory();
    const dispatch = useDispatch();
    const nextRoute = useSelector(selectNextRoute);
    const [errorMessage, setErrorMessage] = useState('');
    const rechnungsempfaenger = useSelector((state: RootState) => state.rechnungsempfaenger.rechnungsempfaenger);
    const rechnungsempfaengerLoaded = useSelector((state: RootState) => state.rechnungsempfaenger.loadFinished);
    const isDebitor = useSelector(selectIsDebitor);
    const isFarmleiter = useSelector(selectIsFarmleiter);
    const debitorBezeichnung = isDebitor || isFarmleiter ? 'Rechnungsempfänger' : 'Landwarenhandel';
    const keineRechnungsempfaenger = Array.isArray(rechnungsempfaenger) && rechnungsempfaenger.length === 0 && rechnungsempfaengerLoaded;
    useDocumentTitle(`${debitorBezeichnung} auswählen`);

    useEffect(() => {
        dispatch(setCurrentWorkflowStep(WorkflowStep.AuswahlRechnungsempfaenger, history));
        dispatch(getRechnungsempfaenger());
    }, [dispatch, history]);

    const isLoading = useSelector((state: RootState) => state.rechnungsempfaenger.isLoading);
    const hasLoadError = useSelector((state: RootState) => state.rechnungsempfaenger.hasLoadError);
    const ausgewaehlterRechnungsempfaenger = useSelector((state: RootState) => state.bestellung.rechnungsempfaengerPartnerNummer);
    const hasBestellpositionen = useSelector(
        (state: RootState) => state.bestellung.bestellpositionen && state.bestellung.bestellpositionen.length > 0
    );

    const validateSelection = (): void => {
        if (!ausgewaehlterRechnungsempfaenger) {
            setErrorMessage(`Bitte wählen Sie einen ${debitorBezeichnung} aus.`);
            return;
        }
        history.push(nextRoute);
    };

    return (
        <>
            <h2>{debitorBezeichnung} auswählen</h2>
            <NotificationBar
                isVisible={keineRechnungsempfaenger}
                message={
                    isDebitor || isFarmleiter
                        ? 'Ihrem Nutzer sind keine Rechnungsempfänger zugeordnet.'
                        : 'Für diese Kundennummer ist kein aktiver Landwarenhandel hinterlegt. Bitte nehmen Sie dazu Kontakt mit ' +
                          'Ihrem Futterhändler auf, damit dieser den Bezugsweg an die Deutsche Tiernahrung Cremer weitergibt.'
                }
            />
            <NotificationBar
                dataCy="bestellpositionen-error-message-bar"
                testId="bestellpositionen-error-message-bar"
                message={errorMessage}
                isVisible={errorMessage !== ''}
            />
            <NotificationBar
                testId="load-error-message-bar"
                message={`Die ${debitorBezeichnung} konnten nicht geladen werden. Bitte versuchen Sie es noch einmal.`}
                actionText="Neu laden"
                actionCallback={(): void => {
                    dispatch(getRechnungsempfaenger());
                }}
                isVisible={hasLoadError}
            />
            <LoadingSpinner isLoading={isLoading}>
                <>
                    <div className={styles._rechnungsempfaengerContainer}>
                        {rechnungsempfaenger.map((re: Rechnungsempfaenger, index) => {
                            return (
                                <SelectableCard
                                    dataCy={`workflow-rechnungsempfaenger`}
                                    key={index}
                                    testId={'RE-' + re.partnerNummer}
                                    selected={re.partnerNummer === ausgewaehlterRechnungsempfaenger}
                                    onClick={(): void => {
                                        if (ausgewaehlterRechnungsempfaenger !== re.partnerNummer && hasBestellpositionen) {
                                            showConfirmationDialog({
                                                message: (
                                                    <>
                                                        Sie haben noch eine Bestellung, die nicht abgeschlossen ist. Wenn Sie fortfahren
                                                        werden Ihre Bestellpositionen gelöscht.
                                                    </>
                                                ),
                                                okButtonText: 'Bestellpositionen löschen',
                                                onOkCallback: () => {
                                                    dispatch(setBestellpositionen([]));
                                                    dispatch(setRechnungsempfaenger(re.partnerNummer));
                                                },
                                            });
                                        } else {
                                            dispatch(setRechnungsempfaenger(re.partnerNummer));
                                        }
                                        setErrorMessage('');
                                    }}
                                >
                                    <div>{re.hauptname}</div>
                                    <div>{re.strasse}</div>
                                    <div className={styles._plzOrt}>
                                        {re.postleitzahl} {re.ort}
                                    </div>
                                </SelectableCard>
                            );
                        })}
                    </div>
                    <WorkflowButtons onNextClick={validateSelection} />
                </>
            </LoadingSpinner>
        </>
    );
};

export default RechnungsempfaengerAuswaehlen;
