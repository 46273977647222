import { convertDateWithTimeFromIsoStringToDateInfo } from './date-helper';
import { OptionTypeBase } from 'react-select';
import { Berechnungsart, Lieferbedingungen, LieferbedingungLabel, Rollen } from '../types/enums';
import type { Kontrakt } from '../types';
import { ReactElement } from 'react';

export type Option = {
    value: string;
    label: string | ReactElement;
} & OptionTypeBase;

export const sortOptions = (a: Option, b: Option): number => {
    if (a.label < b.label) {
        return -1;
    }
    if (a.label > b.label) {
        return 1;
    }
    return 0;
};

export const removeDuplicateOptions = (options: Option[]): Option[] => {
    const set = new Set();

    return options.filter((item) => {
        const alreadyHas = set.has(item['value']);
        set.add(item['value']);

        return !alreadyHas;
    });
};

export const mergeAndSortOptions = (optionsA: Option[], optionsB: Option[]): Option[] => {
    return removeDuplicateOptions(optionsA.concat(optionsB)).sort(sortOptions);
};

export const getBerechnungsArtOptions = (rolle: string[]): Option[] => {
    switch (rolle[0]) {
        case Rollen.FARMLEITER:
            return [berechnungsartOptions[0]];
        case Rollen.RECIPIENT:
        case Rollen.DEBITOR:
        case Rollen.PROSPECT:
            return berechnungsartOptions;
        default:
            return [];
    }
};

export const berechnungsartOptions: Option[] = [
    { value: Berechnungsart.KeinVorgabe, label: 'Keine Vorgabe' },
    { value: Berechnungsart.Kontrakt, label: 'Kontrakt' },
    { value: Berechnungsart.Tagespreis, label: 'Tagespreis' },
];

export const lieferbedingungOptions = [
    { label: LieferbedingungLabel.EX_WERK, value: Lieferbedingungen.EX_WERK },
    { label: LieferbedingungLabel.FRANCO, value: Lieferbedingungen.FRANCO },
];

export enum Tageszeit {
    Vormittags = '09:00',
    Nachmittags = '14:00',
}

export const tageszeitOptions: Option[] = [
    { value: Tageszeit.Vormittags, label: 'Vormittags' },
    { value: Tageszeit.Nachmittags, label: 'Nachmittags' },
];

export const optionsFromKontrakte = (kontrakte?: Kontrakt[]): Option[] => {
    if (!kontrakte) {
        return [];
    }

    return kontrakte.map((kontrakt) => {
        const lieferdatumAbDateInfo = convertDateWithTimeFromIsoStringToDateInfo(kontrakt.lieferDatumAb);
        const lieferdatumBisDateInfo = convertDateWithTimeFromIsoStringToDateInfo(kontrakt.lieferDatumBis);

        const offenInTons = kontrakt.restmenge.toLocaleString('de-DE', {
            maximumFractionDigits: 2,
            useGrouping: true,
            minimumFractionDigits: 0,
        });

        const abschlussmengeInTons = kontrakt.abschlussmenge.toLocaleString('de-DE', {
            maximumFractionDigits: 2,
            useGrouping: true,
            minimumFractionDigits: 0,
        });

        return {
            ff: kontrakt.kontraktNummer,
            value: kontrakt.kontraktNummer,
            label: (
                <>
                    <div>
                        <strong>{kontrakt.kontraktNummer}</strong>
                    </div>
                    <div>
                        {kontrakt.kontraktpartnerName} ({kontrakt.kontraktpartnerNummer})
                    </div>
                    <div>
                        <strong>
                            Offen:&nbsp;
                            {offenInTons}t
                        </strong>
                        &nbsp;
                        <span>
                            von&nbsp;
                            {abschlussmengeInTons}t
                        </span>
                    </div>
                    <div>
                        {lieferdatumAbDateInfo.datum} - {lieferdatumBisDateInfo.datum}
                    </div>
                </>
            ),
        };
    });
};

export const rollenOptions = [
    { label: 'Debitor', value: Rollen.DEBITOR },
    { label: 'Farmleiter', value: Rollen.FARMLEITER },
    { label: 'Warenempfänger', value: Rollen.RECIPIENT },
    { label: 'Interessent', value: Rollen.PROSPECT },
];

export const rollenOptionsIncludingAdmin = [...rollenOptions, { label: 'Administrator', value: Rollen.ADMIN }];
