import SelectInput from '../../../../ui-components/SelectInput/SelectInput';
import FormControl from '../../../../ui-components/FormControl/FormControl';
import { PropertyEntryReadonlyProps } from '../types';
import { useWerkOptions } from '../../../../hooks/useWerkOptions';
import TextItem from '../../../../ui-components/TextItem/TextItem';
import { selectIsDebitor, selectIsFarmleiter } from '../../../../../store/Navigation.store';
import { useSelector } from 'react-redux';
import { ReactElement } from 'react';

export const WerkeAuswahlListEntry = ({
    bestellposition,
    disabled = false,
    readonly = false,
}: PropertyEntryReadonlyProps): ReactElement | null => {
    const werkOptions = useWerkOptions(bestellposition.nummer);
    const isDebitor = useSelector(selectIsDebitor);
    const isFarmleiter = useSelector(selectIsFarmleiter);
    if (!isDebitor && !isFarmleiter) {
        return null;
    }

    if (werkOptions.length === 0) {
        return null;
    }

    if (readonly) {
        const werkName = bestellposition.werk || werkOptions.find((werk) => werk.value === bestellposition.werkId)?.label;
        return <TextItem label="Werk" text={werkName} isStorniert={bestellposition.storno} />;
    }

    return (
        <FormControl
            name={`bestellpositionenView[${bestellposition.index}].werkId`}
            id={`bestellpositionenView[${bestellposition.index}].werkId`}
            label="Werk *"
            renderChild={(props): ReactElement => (
                <SelectInput
                    storniert={bestellposition.storno}
                    isDisabled={disabled}
                    options={werkOptions}
                    placeholder="Auswahl Werk"
                    {...props}
                    isClearable={false}
                    autoSelectIfOnlyOneOption
                />
            )}
        />
    );
};
