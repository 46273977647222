import { KeyboardEvent, ReactElement, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import NotificationBar from '../../shared/ui-components/NotificationBar/NotificationBar';
import formStyles from '../../shared/ui-components/Form/Form.module.scss';
import FormControl from '../../shared/ui-components/FormControl/FormControl';
import TextInput from '../../shared/ui-components/TextInput/TextInput';
import SelectInput from '../../shared/ui-components/SelectInput/SelectInput';
import { ADD_DE, ADD_WE, deleteUser, getUserDetails, REMOVE_DE, REMOVE_WE, SET_ROLLE, updateUser } from '../../store/User.store';
import ButtonGroup from '../../shared/ui-components/ButtonGroup/ButtonGroup';
import Button from '../../shared/ui-components/Button/Button';
import { routes } from '../../store/Workflow.store';
import TextInputWithoutFormik from '../../shared/ui-components/TextInput/TextInputWithoutFormik';
import {
    GET_PARTNER_FROM_DEBITOR_AFTER_DELETE,
    getDebitor,
    getPartner,
    GetPartnerFromDebitor,
    RESET_PARTNERS,
    RESET_WE_SEARCH,
} from '../../store/Partner.store';
import { useMediaQuery } from 'react-responsive';
import IconButton, { IconSize } from '../../shared/ui-components/IconButton/IconButton';
import iconTrashcan from '../../assets/icon-trashcan.svg';
import iconAddArrow from '../../assets/icon-add-arrow.svg';
import LoadingSpinner from '../../shared/ui-components/LoadingSpinner/LoadingSpinner';
import FeatureToggle from '../../shared/ui-components/FeatureToggle/FeatureToggle';
import { showConfirmationDialog } from '../../shared/ui-components/ConfirmationDialog/confirmationDialog';
import { Option, rollenOptions } from '../../shared/helper/select-options';
import type { RootState } from '../../configureStore';
import NonFormikCheckbox from '../../shared/ui-components/CheckBox/NonFormikCheckbox';
import useDocumentTitle from '../../shared/hooks/useDocumentTitle';
import { RegistrierungsStatus, Rollen } from '../../shared/types/enums';
import type { Partner, User, UserDetailsParam } from '../../shared/types';
import styles from './UserDetail.module.scss';

type SubComponentProps = {
    formikProps: FormikProps<User>;
    setIsFarmLeiter?: React.Dispatch<React.SetStateAction<boolean>>;
};

type PartnerView = Partner & { selected: boolean };

const PartnerZuordnung = ({ formikProps }: SubComponentProps): ReactElement => {
    const dispatch = useDispatch();
    const selectedPartner = useSelector((state: RootState) => state.user.selectedUser.partnerListe);
    // ...(state.user.selectedUser.warenEmpfaengerListe || []),);
    const [selectedPartnerView, setSelectedPartnerView] = useState<PartnerView[]>([]);
    const partnerFromStore = useSelector((state: RootState) => state.partnersuche.partner);
    const isLoadingPartner = useSelector((state: RootState) => state.partnersuche.isLoading);
    const [searchTerm, setSearchTerm] = useState('');
    const [partnerView, setPartnerView] = useState<PartnerView[]>([]);
    const isMobile = useMediaQuery({ query: `(max-width: ${parseInt(styles['breakpoint-tablet-portrait-up']) - 1 + 'px'})` });

    const updatePartnerInFormikProps = (selectedPartnerViewNext: PartnerView[]): void => {
        const selectedPartnerNext = selectedPartnerViewNext.map(({ selected: _, ...sp }) => sp);
        void formikProps.setFieldValue('partnerListe', selectedPartnerNext);
    };

    useEffect(() => {
        setPartnerView(partnerFromStore.map((p) => ({ ...p, selected: false })));
    }, [partnerFromStore, setPartnerView]);

    useEffect(() => {
        if (selectedPartner) {
            setSelectedPartnerView(selectedPartner.map((p) => ({ ...p, selected: false })));
        }
    }, [selectedPartner, setSelectedPartnerView]);

    const filteredPartnerView = partnerView.filter((p) => !selectedPartnerView.some((pv) => p.partnerNummer === pv.partnerNummer));

    const addSelectedPartner = (): void => {
        const selectedPartnerViewNext = [...selectedPartnerView, ...filteredPartnerView.filter((pv) => pv.selected)].map((p) => ({
            ...p,
            selected: false,
        }));
        setSelectedPartnerView(selectedPartnerViewNext);
        updatePartnerInFormikProps(selectedPartnerViewNext);
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Enter' || event.code === 'Enter') {
            dispatch(getPartner(searchTerm));
        }
    };

    return (
        <>
            <h2>Partnerzuordnung</h2>
            <div className={styles._partnerZuordnungContainer}>
                <div className={styles._allePartner}>
                    <h3>Verfügbare Partner</h3>
                    <div className={styles._partnerSuche}>
                        <TextInputWithoutFormik
                            id="partnerSuche"
                            name="partnerSuche"
                            placeholder="Suche nach verfügbaren Partnern"
                            onFieldChange={setSearchTerm}
                            onKeyDown={handleKeyDown}
                        />
                        <Button
                            type="button"
                            onClick={(): void => {
                                dispatch(getPartner(searchTerm));
                            }}
                            disabled={!searchTerm}
                        >
                            {isMobile ? '🔍' : 'Suche'}
                        </Button>
                    </div>
                    <div className={[styles._listContainer, styles['_listContainer--fixedHeight']].join(' ')}>
                        <LoadingSpinner isLoading={isLoadingPartner} marginTop="85px">
                            <>
                                {filteredPartnerView.map((p, index) => (
                                    <NonFormikCheckbox
                                        id={p.partnerNummer}
                                        name={p.partnerNummer}
                                        multiline={true}
                                        placement="left"
                                        label={
                                            <span>
                                                <strong>{p.hauptname}</strong>
                                                <br />
                                                {p.partnerNummer}
                                                <br />
                                                {p.postleitzahl} {p.ort}
                                            </span>
                                        }
                                        key={index}
                                        checked={p.selected}
                                        onFieldChange={(checked: boolean): void => {
                                            setPartnerView(
                                                partnerView.map((pvLocal) =>
                                                    pvLocal.partnerNummer !== p.partnerNummer
                                                        ? pvLocal
                                                        : {
                                                              ...pvLocal,
                                                              selected: checked,
                                                          }
                                                )
                                            );
                                        }}
                                    />
                                ))}
                            </>
                        </LoadingSpinner>
                    </div>
                </div>
                <div className={styles._zuordnungActions}>
                    <IconButton icon={iconAddArrow} iconSize={IconSize.LARGE} alt="Partner hinzufügen" onClick={addSelectedPartner} />
                </div>
                <div className={styles._ausgewaehltePartner}>
                    <h3>Zugeordnete Partner</h3>
                    <div className={[styles._listContainer, styles['_listContainer--fullHeight']].join(' ')}>
                        {selectedPartnerView.map((partner, index) => (
                            <div key={index} className={styles._selectedEntry}>
                                <div>
                                    <strong>{partner.hauptname}</strong>
                                    <br />
                                    {partner.partnerNummer}
                                    <br />
                                    {partner.postleitzahl} {partner.ort}
                                </div>
                                <IconButton
                                    onClick={(): void => {
                                        const selectedPartnerViewNext = selectedPartnerView.filter(
                                            (partnerInView) => partnerInView.partnerNummer !== partner.partnerNummer
                                        );
                                        setSelectedPartnerView(selectedPartnerViewNext);
                                        setPartnerView(partnerView.map((partnerInView) => ({ ...partnerInView, selected: false })));
                                        updatePartnerInFormikProps(selectedPartnerViewNext);
                                    }}
                                    icon={iconTrashcan}
                                    alt="Partnerzuordnung löschen"
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

const FarmleiterPartnerZuordnung = ({ formikProps }: SubComponentProps): ReactElement => {
    const dispatch = useDispatch();
    const selectedDebitorPartner = useSelector((state: RootState) => state.user.selectedUser.partnerListe);
    const [selectedDebitorPartnerView, setSelectedDebitorPartnerView] = useState<PartnerView[]>([]);
    const debitorFromStore = useSelector((state: RootState) => state.partnersuche.partner);
    const [debitorSearchTerm, setDebitorSearchTerm] = useState('');
    const [debitorView, setDebitorView] = useState<PartnerView[]>([]);

    const isLoadingPartner = useSelector((state: RootState) => state.partnersuche.isLoading);

    const allWarenEmpfaenger = useSelector((state: RootState) => {
        return state.partnersuche.warenEmpfaengerPartner;
    });

    const selectedWarenEmpfaengerListe = useSelector((state: RootState) => state.user.selectedUser.warenEmpfaengerListe || []);
    const [selectedWarenEmpfaenger, setSeletedWarenEmpfaenger] = useState<PartnerView[]>([]);
    const [warenEmpfaengerView, setWarenEmpangerView] = useState<PartnerView[]>(
        selectedWarenEmpfaengerListe?.map((w) => {
            return { ...w, checked: false, selected: false };
        }) || []
    );
    const [warenempfaengerSearchTerm, setWarenempfaengerSearchTerm] = useState('');
    const isMobile = useMediaQuery({ query: `(max-width: ${parseInt(styles['breakpoint-tablet-portrait-up']) - 1 + 'px'})` });

    const updatePartnerInFormikProps = (selectedPartnerViewNext: PartnerView[]): void => {
        const selectedPartnerNext = selectedPartnerViewNext.map(({ selected: _, ...sp }) => sp);
        void formikProps.setFieldValue('partnerListe', selectedPartnerNext);
    };

    const updateWarenempfaengerInFormikProps = (selectedPartnerViewNext: PartnerView[]): void => {
        const selectedPartnerNext = selectedPartnerViewNext.map(({ selected: _, ...sp }) => sp);
        void formikProps.setFieldValue('warenEmpfaengerListe', selectedPartnerNext);
    };

    useEffect(() => {
        setDebitorView(debitorFromStore.map((p) => ({ ...p, selected: false })));
    }, [debitorFromStore, setDebitorView]);

    useEffect(() => {
        setWarenEmpangerView(allWarenEmpfaenger.map((p) => ({ ...p, selected: false })));
    }, [allWarenEmpfaenger, setWarenEmpangerView]);

    useEffect(() => {
        if (selectedDebitorPartner) {
            setSelectedDebitorPartnerView(selectedDebitorPartner.map((p) => ({ ...p, selected: false })));
        }
    }, [selectedDebitorPartner, setSelectedDebitorPartnerView]);
    // check warenempfaenger are still in list adter allWarenEmpfaenger updates
    useEffect(() => {
        if (selectedWarenEmpfaengerListe)
            setSeletedWarenEmpfaenger(
                selectedWarenEmpfaengerListe.map((p) => ({
                    ...p,
                    selected: false,
                }))
            );
    }, [selectedWarenEmpfaengerListe, setWarenEmpangerView, setSeletedWarenEmpfaenger, allWarenEmpfaenger]);

    const filteredPartnerView = debitorView.filter((p) => !selectedDebitorPartnerView.some((pv) => p.partnerNummer == pv.partnerNummer));
    const filteredWarenEmpfaengerView = warenEmpfaengerView.filter(
        (p) => !selectedWarenEmpfaenger.some((pv) => p.partnerNummer == pv.partnerNummer)
    );

    const addSelectedPartner = (): void => {
        const selectedPartnerViewNext = [...selectedDebitorPartnerView, ...filteredPartnerView.filter((pv) => pv.selected)].map((p) => ({
            ...p,
            selected: false,
        }));
        setSelectedDebitorPartnerView(selectedPartnerViewNext);
        updatePartnerInFormikProps(selectedPartnerViewNext);
        dispatch({
            type: ADD_DE,
            payload: selectedPartnerViewNext,
        });
    };

    const addSelectedWarenEmpfaenger = (): void => {
        const selectedPartnerViewNext = [
            ...selectedWarenEmpfaenger,
            ...filteredWarenEmpfaengerView.filter(
                (pv) => pv.selected && !selectedWarenEmpfaengerListe.some((p) => p.partnerNummer === pv.partnerNummer)
            ),
        ].map((p) => ({
            ...p,
            selected: false,
        }));
        setSeletedWarenEmpfaenger(selectedPartnerViewNext);
        updateWarenempfaengerInFormikProps(selectedPartnerViewNext);
        dispatch({ type: ADD_WE, partner: selectedPartnerViewNext });
    };

    //enter methods
    const handleDebitorKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Enter' || event.code === 'Enter') {
            dispatch(getDebitor(debitorSearchTerm));
        }
    };

    const handleWEKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Enter' || event.code === 'Enter') {
            dispatch(GetPartnerFromDebitor(selectedDebitorPartnerView, warenempfaengerSearchTerm));
        }
    };

    console.log(formikProps.values);

    return (
        <>
            <h2>Debitor Zuordnung</h2>
            <div className={styles._partnerZuordnungContainer}>
                <div className={styles._allePartner}>
                    <h3>Verfügbare Partner</h3>
                    <div className={styles._partnerSuche}>
                        <TextInputWithoutFormik
                            id="debitorPartnerSuche"
                            name="debitorPartnerSuche"
                            placeholder="Suche nach verfügbaren Partnern"
                            onFieldChange={setDebitorSearchTerm}
                            onKeyDown={handleDebitorKeyDown}
                        />
                        <Button
                            type="button"
                            onClick={(): void => {
                                dispatch(getDebitor(debitorSearchTerm));
                            }}
                            disabled={!debitorSearchTerm}
                        >
                            {isMobile ? '🔍' : 'Suche'}
                        </Button>
                    </div>
                    <div className={[styles._listContainer, styles['_listContainer--fixedHeight']].join(' ')}>
                        <LoadingSpinner isLoading={isLoadingPartner} marginTop="85px">
                            <>
                                {filteredPartnerView.map((p, index) => (
                                    <NonFormikCheckbox
                                        id={p.partnerNummer}
                                        name={p.partnerNummer}
                                        multiline={true}
                                        placement="left"
                                        label={
                                            <span>
                                                <strong>{p.hauptname}</strong>
                                                <br />
                                                {p.partnerNummer}
                                                <br />
                                                {p.postleitzahl} {p.ort}
                                            </span>
                                        }
                                        key={index}
                                        checked={p.selected}
                                        onFieldChange={(checked: boolean): void => {
                                            setDebitorView(
                                                debitorView.map((pvLocal) =>
                                                    pvLocal.partnerNummer !== p.partnerNummer
                                                        ? pvLocal
                                                        : {
                                                              ...pvLocal,
                                                              selected: checked,
                                                          }
                                                )
                                            );
                                        }}
                                    />
                                ))}
                            </>
                        </LoadingSpinner>
                    </div>
                </div>
                <div className={styles._zuordnungActions}>
                    <IconButton icon={iconAddArrow} iconSize={IconSize.LARGE} alt="Partner hinzufügen" onClick={addSelectedPartner} />
                </div>
                <div className={styles._ausgewaehltePartner}>
                    <h3>Zugeordnete Partner</h3>
                    <div className={[styles._listContainer, styles['_listContainer--fullHeight']].join(' ')}>
                        {selectedDebitorPartnerView.map((partner, index) => (
                            <div key={index} className={styles._selectedEntry}>
                                <div>
                                    <strong>{partner.hauptname}</strong>
                                    <br />
                                    {partner.partnerNummer}
                                    <br />
                                    {partner.postleitzahl} {partner.ort}
                                </div>
                                <IconButton
                                    onClick={(): void => {
                                        const selectedPartnerViewNext = selectedDebitorPartnerView.filter(
                                            (partnerInView) => partnerInView.partnerNummer !== partner.partnerNummer
                                        );
                                        setSelectedDebitorPartnerView(selectedPartnerViewNext);
                                        setDebitorView(debitorView.map((partnerInView) => ({ ...partnerInView, selected: false })));
                                        dispatch({
                                            type: GET_PARTNER_FROM_DEBITOR_AFTER_DELETE,
                                            partner: selectedPartnerViewNext,
                                        });
                                        dispatch({
                                            type: REMOVE_DE,
                                            payload: partner,
                                        });
                                        dispatch({ type: RESET_WE_SEARCH });
                                        updatePartnerInFormikProps(selectedPartnerViewNext);
                                    }}
                                    icon={iconTrashcan}
                                    alt="Partnerzuordnung löschen"
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <br />
            <h2>Warenempfänger Zuordnung</h2>
            <div className={styles._partnerZuordnungContainer}>
                <div className={styles._allePartner}>
                    <h3>Verfügbare Partner</h3>
                    <div className={styles._partnerSuche}>
                        <TextInputWithoutFormik
                            id="partnerSuche"
                            name="partnerSuche"
                            placeholder="Suche nach verfügbaren Partnern"
                            onFieldChange={setWarenempfaengerSearchTerm}
                            onKeyDown={handleWEKeyDown}
                        />
                        <Button
                            type="button"
                            onClick={(): void => {
                                dispatch(GetPartnerFromDebitor(selectedDebitorPartnerView, warenempfaengerSearchTerm));
                            }}
                            disabled={!warenempfaengerSearchTerm}
                        >
                            {isMobile ? '🔍' : 'Suche'}
                        </Button>
                    </div>
                    <div className={[styles._listContainer, styles['_listContainer--fixedHeight']].join(' ')}>
                        <LoadingSpinner isLoading={isLoadingPartner} marginTop="85px">
                            <>
                                {filteredWarenEmpfaengerView.map((p, index) => (
                                    <NonFormikCheckbox
                                        id={p.partnerNummer}
                                        name={p.partnerNummer}
                                        multiline={true}
                                        placement="left"
                                        label={
                                            <span>
                                                <strong>{p.hauptname}</strong>
                                                <br />
                                                {p.partnerNummer}
                                                <br />
                                                {p.postleitzahl} {p.ort}
                                            </span>
                                        }
                                        key={index}
                                        checked={p.selected}
                                        onFieldChange={(checked: boolean): void => {
                                            setWarenEmpangerView(
                                                warenEmpfaengerView.map((pvLocal) =>
                                                    pvLocal.partnerNummer !== p.partnerNummer
                                                        ? pvLocal
                                                        : {
                                                              ...pvLocal,
                                                              selected: checked,
                                                          }
                                                )
                                            );
                                        }}
                                    />
                                ))}
                            </>
                        </LoadingSpinner>
                    </div>
                </div>
                <div className={styles._zuordnungActions}>
                    <IconButton
                        icon={iconAddArrow}
                        iconSize={IconSize.LARGE}
                        alt="Partner hinzufügen"
                        onClick={addSelectedWarenEmpfaenger}
                    />
                </div>
                <div className={styles._ausgewaehltePartner}>
                    <h3>Zugeordnete Partner</h3>
                    <div className={[styles._listContainer, styles['_listContainer--fullHeight']].join(' ')}>
                        {selectedWarenEmpfaenger.map((partner, index) => (
                            <div key={index} className={styles._selectedEntry}>
                                <div>
                                    <strong>{partner.hauptname}</strong>
                                    <br />
                                    {partner.partnerNummer}
                                    <br />
                                    {partner.postleitzahl} {partner.ort}
                                </div>
                                <IconButton
                                    onClick={(): void => {
                                        const selectedPartnerViewNext = selectedWarenEmpfaenger.filter(
                                            (partnerInView) => partnerInView.partnerNummer !== partner.partnerNummer
                                        );
                                        setSeletedWarenEmpfaenger(selectedPartnerViewNext);
                                        setWarenEmpangerView(
                                            warenEmpfaengerView.map((partnerInView) => ({
                                                ...partnerInView,
                                                selected: false,
                                            }))
                                        );
                                        updateWarenempfaengerInFormikProps(selectedPartnerViewNext);
                                        dispatch({ type: REMOVE_WE, partnerNumer: partner.partnerNummer });
                                    }}
                                    icon={iconTrashcan}
                                    alt="Partnerzuordnung löschen"
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

const Benutzerdaten = ({ formikProps, setIsFarmLeiter }: SubComponentProps): ReactElement => {
    const isAdminInitially = formikProps.values.rolle === Rollen.ADMIN;
    const [hasAdminRole, setHasAdminRole] = useState(isAdminInitially);
    const dispatch = useDispatch();
    useEffect(() => {
        setHasAdminRole(isAdminInitially);
    }, [isAdminInitially]);
    useEffect(() => setIsFarmLeiter?.(formikProps.values.rolle == Rollen.FARMLEITER), [setIsFarmLeiter]);
    return (
        <div className={formStyles._formContainer}>
            <div className={formStyles._formColumn}>
                <h3>Benutzer</h3>
                <FormControl
                    name="vorname"
                    id="vorname"
                    label="Vorname"
                    renderChild={(props): ReactElement => <TextInput {...props} disabled />}
                />
                <FormControl
                    name="nachname"
                    id="nachname"
                    label="Nachname"
                    renderChild={(props): ReactElement => <TextInput {...props} disabled />}
                />
                <FormControl
                    name="id"
                    id="id"
                    label="Benutzer-ID"
                    renderChild={(props): ReactElement => <TextInput {...props} disabled />}
                />
                <FormControl
                    name="email"
                    id="email"
                    label="E-Mail"
                    renderChild={(props): ReactElement => <TextInput {...props} disabled />}
                />
                <h3 className={styles._subHeadline}>Rolle</h3>
                <FormControl
                    name="registration.registrierenAls"
                    id="registration.registrierenAls"
                    label="Registrieren als"
                    renderChild={(props): ReactElement => <TextInput {...props} disabled />}
                />

                <FormControl
                    name="rolle"
                    id="rolle"
                    label="Rolle *"
                    renderChild={(props): ReactElement => (
                        <SelectInput<Option>
                            {...props}
                            setFieldTouched={formikProps.setFieldTouched}
                            options={rollenOptions}
                            placeholder={hasAdminRole ? '' : 'bitte auswählen'}
                            isDisabled={hasAdminRole}
                            isClearable={false}
                            autoSelectIfOnlyOneOption={true}
                            onValueChange={function setFarmleiter(val) {
                                if (formikProps.values.rolle != Rollen.FARMLEITER && val == Rollen.FARMLEITER)
                                    dispatch({ type: RESET_PARTNERS });
                                if (val == Rollen.FARMLEITER) setIsFarmLeiter?.(true);
                                else setIsFarmLeiter?.(false);
                                dispatch({ type: SET_ROLLE, rolle: val });
                            }}
                        />
                    )}
                />
                <NonFormikCheckbox
                    id="adminRole"
                    name="adminRole"
                    label="Administratorrechte"
                    placement="left"
                    checked={hasAdminRole}
                    onFieldChange={(checked): void => {
                        setHasAdminRole(checked);
                        void formikProps.setFieldValue('rolle', checked ? Rollen.ADMIN : Rollen.PROSPECT);
                    }}
                />
            </div>
            <div className={formStyles._formColumn}>
                <h3>Unternehmen</h3>
                <FormControl
                    name="registration.unternehmen"
                    id="registration.unternehmen"
                    label="Unternehmen"
                    renderChild={(props): ReactElement => <TextInput {...props} disabled />}
                />
                <FormControl
                    name="registration.kundenNummern"
                    id="registration.kundenNummern"
                    label="Kundennummer"
                    renderChild={(props): ReactElement => <TextInput {...props} disabled />}
                />
                <FormControl
                    name="registration.strasse"
                    id="registration.strasse"
                    label="Strasse"
                    renderChild={(props): ReactElement => <TextInput {...props} disabled />}
                />
                <FormControl
                    name="registration.hausnummer"
                    id="registration.hausnummer"
                    label="Hausnummer"
                    renderChild={(props): ReactElement => <TextInput {...props} disabled />}
                />
                <FormControl
                    name="registration.postleitzahl"
                    id="registration.postleitzahl"
                    label="Postleitzahl"
                    renderChild={(props): ReactElement => <TextInput {...props} disabled />}
                />
                <FormControl
                    name="registration.stadt"
                    id="registration.stadt"
                    label="Stadt"
                    renderChild={(props): ReactElement => <TextInput {...props} disabled />}
                />
                <FormControl
                    name="registration.land"
                    id="registration.land"
                    label="Land"
                    renderChild={(props): ReactElement => <TextInput {...props} disabled />}
                />
                <FormControl
                    name="registration.telefon"
                    id="registration.telefon"
                    label="Telefon"
                    renderChild={(props): ReactElement => <TextInput {...props} disabled />}
                />
                <FormControl
                    name="registration.haendler"
                    id="registration.haendler"
                    label="Über welche Händler bezieht der Benutzer?"
                    renderChild={(props): ReactElement => <TextInput {...props} disabled />}
                />
            </div>
        </div>
    );
};

export const UserDetail = (): ReactElement => {
    const { userId } = useParams<UserDetailsParam>();
    const user = useSelector((state: RootState) => state.user.selectedUser);
    const isLoading = useSelector((state: RootState) => state.user.isLoadingDetails);
    const hasSubmitError = useSelector((state: RootState) => state.user.hasSubmitError);
    const hasDeleteError = useSelector((state: RootState) => state.user.hasDeleteError);
    const isSubmitting = useSelector((state: RootState) => state.user.isSubmitting);
    const isDeleting = useSelector((state: RootState) => state.user.isDeleting);
    const [isFarmleiter, setIsFarmLeiter] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    useDocumentTitle('Benutzerdetails');

    useEffect(() => {
        dispatch(getUserDetails(userId));
    }, [dispatch, userId]);

    useEffect(() => {
        setIsFarmLeiter(user?.rolle == Rollen.FARMLEITER);
    }, [user]);

    return (
        <Formik
            initialValues={user}
            validationSchema={Yup.object({
                rolle: Yup.string().required('Bitte weisen Sie dem Benutzer eine Rolle zu'),
            })}
            onSubmit={(values: User): void => {
                values.registrationValidated = true;
                if (values.rolle === Rollen.ADMIN) {
                    showConfirmationDialog({
                        onOkCallback: (): void => {
                            dispatch(updateUser(values, history));
                        },
                        okButtonText: 'Benutzer speichern',
                        okIsPrimaryButton: false,
                        message:
                            'Sie sind im Begriff einem Benutzer Administratorrechte zu verteilen. Die Vergabe dieser Rechte ist ' +
                            'ausschließlich den Mitarbeitern der Zentralen Verkaufsorganisation vorbehalten! Bitte brechen Sie den Vorgang' +
                            ' umgehend ab und wenden Sie sich an einen Mitarbeiter der Zentralen Verkaufsorganisation.',
                    });
                } else {
                    dispatch(updateUser(values, history));
                }
            }}
            validateOnChange={false}
            enableReinitialize
        >
            {(formikProps: FormikProps<User>): ReactElement => {
                return (
                    <>
                        <Form>
                            <h2>Benutzerdaten</h2>
                            <NotificationBar
                                testId="server-validation-message-bar"
                                message="Die Benutzerdaten konnten nicht gespeichert werden. Bitte versuchen Sie es erneut."
                                isVisible={hasSubmitError}
                                actionText={'Benutzerdaten erneut speichern.'}
                                actionCallback={(): void => {
                                    dispatch(updateUser(user, history));
                                }}
                            />
                            <NotificationBar
                                testId="server-validation-message-bar"
                                message="Die Registrierung konnte nicht gelöscht werden. Bitte versuchen Sie es erneut."
                                isVisible={hasDeleteError}
                                actionText={'Registrierung löschen.'}
                                actionCallback={(): void => {
                                    dispatch(deleteUser(user, history));
                                }}
                            />
                            <Benutzerdaten formikProps={formikProps} setIsFarmLeiter={setIsFarmLeiter} />
                            <div>
                                {isFarmleiter ? (
                                    <FarmleiterPartnerZuordnung formikProps={formikProps}></FarmleiterPartnerZuordnung>
                                ) : (
                                    <PartnerZuordnung formikProps={formikProps}></PartnerZuordnung>
                                )}
                            </div>
                        </Form>
                        <ButtonGroup>
                            {!user.registrationValidated && (
                                <FeatureToggle featureName={'DELETE_REGISTRATION'}>
                                    <Button
                                        type="button"
                                        disabled={isLoading || isSubmitting || isDeleting}
                                        onClick={(): void => {
                                            showConfirmationDialog({
                                                message:
                                                    'Die Registrierungsanfrage wird gelöscht. Diesen Vorgang können Sie nicht rückgängig machen.',
                                                okButtonText: 'Registrierung löschen',
                                                onOkCallback: (): void => {
                                                    dispatch(deleteUser(user, history));
                                                },
                                            });
                                        }}
                                        isSecondary={true}
                                    >
                                        {isDeleting ? 'Wird gelöscht...' : 'Registrierung löschen'}
                                    </Button>
                                </FeatureToggle>
                            )}
                            <Button
                                type="button"
                                onClick={(): void =>
                                    history.push(
                                        `${routes.benutzer}/${
                                            user.registrationValidated ? RegistrierungsStatus.REGISTRIERT : RegistrierungsStatus.NEU
                                        }`
                                    )
                                }
                                isSecondary={true}
                            >
                                Abbrechen
                            </Button>
                            <Button type="button" onClick={formikProps.submitForm} disabled={isLoading || isSubmitting || isDeleting}>
                                {isSubmitting
                                    ? 'Wird gespeichert...'
                                    : user.registrationValidated
                                    ? 'Speichern'
                                    : 'Registrierung bestätigen'}
                            </Button>
                        </ButtonGroup>
                    </>
                );
            }}
        </Formik>
    );
};

export default UserDetail;
