import React from 'react';
import { BestellungDetails } from '../../types';
import { FormRow } from '../Form/FormRow';
import { FormColumnContent } from '../Form/FormColumnContent';
import { getWunschterminDatumText } from '../../helper/date-helper';
import { useSelector } from 'react-redux';
import { selectIsDebitor, selectIsFarmleiter } from '../../../store/Navigation.store';
import { FormColumn } from '../Form/FormColumn';

export const KfzKennzeichenAndSpeditionAndWunschterminFormRow: React.FC<{ bestellung: BestellungDetails }> = ({
    bestellung,
}): JSX.Element => {
    const wunschterminText = getWunschterminDatumText(bestellung.wunschtermin);
    const isDebitor = useSelector(selectIsDebitor);
    const isFarmleiter = useSelector(selectIsFarmleiter);

    return (
        <FormRow>
            <FormColumnContent content={bestellung.kfzKennzeichen} title={'KFZ-Kennzeichen'} />
            <FormColumnContent content={bestellung.spedition} title={'Spediteur'} />
            {isDebitor || isFarmleiter ? (
                <FormColumn />
            ) : (
                <FormColumnContent content={wunschterminText} title={'Wunschtermin Landwarenhandel'} />
            )}
        </FormRow>
    );
};
