import React from 'react';
import { FormRow } from '../Form/FormRow';
import { FormColumnContent } from '../Form/FormColumnContent';
import { FormColumn } from '../Form/FormColumn';
import { BestellungDetails } from '../../types';
import { getWunschterminZeitraumDisplayText } from '../../helper/date-helper';
import { useSelector } from 'react-redux';
import { selectIsDebitor, selectIsFarmleiter } from '../../../store/Navigation.store';

export const WunschterminWeFormRows: React.FC<{ bestellung: BestellungDetails }> = ({ bestellung }) => {
    const isDebitor = useSelector(selectIsDebitor);
    const isFarmleiter = useSelector(selectIsFarmleiter);
    const wunschterminISOWEVon = bestellung.wunschterminWEVon;
    const wunschterminISOWEBis = bestellung.wunschterminWEBis;
    const wunschterminZeitraumDisplayText = getWunschterminZeitraumDisplayText(wunschterminISOWEVon, wunschterminISOWEBis);
    return (
        <FormRow>
            <FormColumnContent
                content={wunschterminZeitraumDisplayText}
                title={`Wunschtermin ${isDebitor || isFarmleiter ? 'Warenempfänger' : ''}`}
            />
            <FormColumn />
        </FormRow>
    );
};
