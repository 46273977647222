import { useSelector } from 'react-redux';
import { selectIsDebitor, selectIsFarmleiter } from '../../../store/Navigation.store';
import { FormColumn } from '../../ui-components/Form/FormColumn';
import formStyles from '../../ui-components/Form/Form.module.scss';
import styles from '../../../views/Bestellzusammenfassung/Bestellzusammenfassung.module.scss';
import { ReactElement } from 'react';
import { useRechnungsempfaengerFromBestellung } from '../../hooks/useRechnungsempfaengerFromBestellung';

export const RechnungsempfaengerFormSection = (): ReactElement => {
    const rechnungsempfaenger = useRechnungsempfaengerFromBestellung();
    const isDebitor = useSelector(selectIsDebitor);
    const isFarmleiter = useSelector(selectIsFarmleiter);
    return (
        <FormColumn isSection>
            <div className={formStyles._sectionHeader}>
                <h3>{isDebitor || isFarmleiter ? 'Rechnungsempfänger' : 'Landwarenhandel'}</h3>
            </div>
            <div>{rechnungsempfaenger?.hauptname}</div>
            <div>{rechnungsempfaenger?.strasse}</div>
            <div className={styles._plzOrt}>
                {rechnungsempfaenger?.postleitzahl} {rechnungsempfaenger?.ort}
            </div>
        </FormColumn>
    );
};
