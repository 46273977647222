import { useSelector } from 'react-redux';
import { BestelluebersichtSelectors } from '../../store/Bestelluebersicht.store';
import { FormRow } from '../../shared/ui-components/Form/FormRow';
import formStyles from '../../shared/ui-components/Form/Form.module.scss';
import { SackwareListReadonly } from '../../shared/content-components/Bestellpositionen/ArtikelSackwareTable/SackwareListReadonly';
import { LoseWareListReadonly } from '../../shared/content-components/Bestellpositionen/ArtikelLoseWareTable/LoseWareListReadonly';
import { LoseWareTableReadonly } from '../../shared/content-components/Bestellpositionen/ArtikelLoseWareTable/LoseWareTableReadonly';
import { WarenempfaengerFormSection } from '../../shared/content-components/Warenempfaenger/WarenempfaengerFormSection';
import { RechnungsempfaengerFormSection } from '../../shared/content-components/Rechnungsempfaenger/RechnungsempfaengerFormSection';
import Button from '../../shared/ui-components/Button/Button';
import ButtonGroup from '../../shared/ui-components/ButtonGroup/ButtonGroup';
import { routes } from '../../shared/constants';
import { useHistory } from 'react-router-dom';
import styles from '../../shared/content-components/Bestellpositionen/Bestellpositionen.module.scss';
import { ModifikationsstatusEntry } from '../../shared/content-components/Bestellpositionen/PropertyEntries';
import { selectIsDebitor, selectIsFarmleiter } from '../../store/Navigation.store';
import { SackwareTableReadonly } from '../../shared/content-components/Bestellpositionen/ArtikelSackwareTable/SackwareTableReadonly';
import { selectIsBestellungExisting } from '../../store/Global.selectors';
import { WunschterminUhrzeitReadonly } from '../../shared/content-components/Wunschtermin/WunschterminUhrzeit';
import { LieferartAndRueckfragenFormRow } from '../../shared/ui-components/FormRows/LieferartAndRueckfragenFormRow';
import { BestellhinweisAndLieferhinweisFormRow } from '../../shared/ui-components/FormRows/BestellhinweisAndLieferhinweisFormRow';
import { KfzKennzeichenAndSpeditionAndWunschterminFormRow } from '../../shared/ui-components/FormRows/KfzKennzeichenAndSpeditionAndWunschterminFormRow';
import { WunschterminWeFormRows } from '../../shared/ui-components/FormRows/WunschterminWeFormRows';
import { ModifikationsstatusLegende } from '../../shared/content-components/Bestellpositionen/PropertyEntries/ModifikationsstatusEntry/ModifikationsstatusLegende';
import { Breakpoint, useBreakpoint } from '../../shared/hooks/useBreakpoint';

export const BestellansichtGesperrt = (): JSX.Element => {
    const history = useHistory();
    const isDebitor = useSelector(selectIsDebitor);
    const isFarmleiter = useSelector(selectIsFarmleiter);
    const isExisting = useSelector(selectIsBestellungExisting);
    const bestellung = useSelector(BestelluebersichtSelectors.currentBestellung);
    const wunschtermin = bestellung.wunschtermin;
    const isMobile = useBreakpoint(Breakpoint.MOBILE);
    const bestellpositionenSackwareView = useSelector(BestelluebersichtSelectors.bestellpositionenSackwareView);
    const bestellpositionenLoseWareView = useSelector(BestelluebersichtSelectors.bestellpositionenLoseWareView);
    const positionWithModifikationsergebnis = useSelector(BestelluebersichtSelectors.currentBestellungHasSackwareWithModifikationsergebnis);

    return (
        <>
            <FormRow>
                <WarenempfaengerFormSection />
                <RechnungsempfaengerFormSection />
            </FormRow>
            <LieferartAndRueckfragenFormRow bestellung={bestellung} />
            <BestellhinweisAndLieferhinweisFormRow bestellung={bestellung} />
            <KfzKennzeichenAndSpeditionAndWunschterminFormRow bestellung={bestellung} />
            <WunschterminWeFormRows bestellung={bestellung} />
            {bestellpositionenSackwareView.length > 0 ? (
                <>
                    <h3>Bestellpositionen Sackware</h3>
                    {(isDebitor || isFarmleiter) && positionWithModifikationsergebnis ? (
                        <div className={styles._modifikationsContainer}>
                            <ModifikationsstatusEntry.List
                                nummer={positionWithModifikationsergebnis.nummer}
                                positionsnummer={positionWithModifikationsergebnis.positionsnummer}
                                withText
                            />
                        </div>
                    ) : null}
                    {(isDebitor || isFarmleiter) && isExisting ? <WunschterminUhrzeitReadonly wunschtermin={wunschtermin} /> : null}
                    {isMobile ? (
                        <SackwareListReadonly bestellpositionenView={bestellpositionenSackwareView} />
                    ) : (
                        <SackwareTableReadonly bestellpositionen={bestellpositionenSackwareView} />
                    )}
                </>
            ) : null}
            {bestellpositionenLoseWareView.length > 0 ? (
                <>
                    <div className={formStyles._sectionHeader}>
                        <h3>Bestellpositionen lose Ware</h3>
                    </div>
                    {isMobile ? (
                        <LoseWareListReadonly bestellpositionen={bestellpositionenLoseWareView} />
                    ) : (
                        <LoseWareTableReadonly bestellpositionen={bestellpositionenLoseWareView} />
                    )}
                </>
            ) : null}
            {(isDebitor || isFarmleiter) && !isMobile && bestellpositionenLoseWareView.length > 0 ? <ModifikationsstatusLegende /> : null}
            <ButtonGroup>
                <Button onClick={(): void => history.push(routes.bestelluebersicht)}>Zurück</Button>
            </ButtonGroup>
        </>
    );
};
