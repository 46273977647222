import { useMemo } from 'react';
import {
    createBestellpositionViewSchemaDraftBestellung,
    createDateSchema,
    createTimeSchema,
} from '../../shared/validation/bestellung/validation';
import { minDate } from '../../shared/helper/date-helper';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { selectIsDebitor, selectIsFarmleiter } from '../../store/Navigation.store';
import { datumWEBisSchema, uhrzeitWEBisSchema } from '../../shared/validation/bestellung/schemas';

function createNonDraftBestellungValidationSchema(
    isDebitor: boolean,
    overWeightConfirmed: boolean,
    minDate: string,
    isFarmleiter: boolean
) {
    return Yup.object({
        notificationCheck: Yup.boolean(),
        bestellpositionenView: Yup.object().when('notificationCheck', {
            is: true,
            then: () => {
                return createBestellpositionViewSchemaDraftBestellung(isDebitor, false, isFarmleiter);
            },
            otherwise: () => {
                return createBestellpositionViewSchemaDraftBestellung(isDebitor, true, isFarmleiter);
            },
        }),
        lieferbedingung: Yup.string().required(),
        datumDebitor: createDateSchema(minDate),
        uhrzeitDebitor: createTimeSchema(),
        datumWE: createDateSchema(minDate, false),
        uhrzeitWE: createTimeSchema(false),
        datumWEVon: createDateSchema(minDate, false),
        datumWEBis: datumWEBisSchema,
        uhrzeitWEVon: createTimeSchema(false),
        uhrzeitWEBis: uhrzeitWEBisSchema,
    });
}

function createDraftBestellungValidationSchema(isDebitor: boolean, isFarmleiter: boolean, overweightConfirmed: boolean) {
    if (overweightConfirmed) {
        return Yup.object({
            bestellpositionenView: createBestellpositionViewSchemaDraftBestellung(isDebitor, false, isFarmleiter),
        });
    } else {
        return Yup.object({
            bestellpositionenView: createBestellpositionViewSchemaDraftBestellung(isDebitor, true, isFarmleiter),
        });
    }
}

// NOTE: Ignore because we could not get yup typings working correctly
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAuftragskorbValidationSchema = (overWeightConfirmed: boolean, saveAsDraft: boolean, storniereAuftrag: boolean) => {
    const isDebitor = useSelector(selectIsDebitor);
    const isFarmleiter = useSelector(selectIsFarmleiter);

    return useMemo(() => {
        if (!storniereAuftrag) {
            if (saveAsDraft) {
                return createDraftBestellungValidationSchema(isDebitor, isFarmleiter, overWeightConfirmed);
            } else {
                return createNonDraftBestellungValidationSchema(isDebitor, overWeightConfirmed, minDate, isFarmleiter);
            }
        }
        return null;
    }, [isDebitor, isFarmleiter, overWeightConfirmed, saveAsDraft, storniereAuftrag]);
};
