import { History, LocationState } from 'history';
import { RegistrierungsStatus, Rollen } from '../shared/types/enums';
import { emptyUser } from '../shared/types/defaultValues';
import type { Partner, User } from '../shared/types';
import type { RootState } from '../configureStore';
import { ResetPartnerSearch } from './Partner.store';

export const GET_USER = 'GET_USER';
export const GET_USER_SUCCEEDED = 'GET_USER_SUCCEEDED';
export const GET_USER_FAILED = 'GET_USER_FAILED';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCEEDED = 'UPDATE_USER_SUCCEEDED';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const GET_USER_DETAILS_SUCCEEDED = 'GET_USER_DETAILS_SUCCEEDED';
export const GET_USER_DETAILS_FAILED = 'GET_USER_DETAILS_FAILED';

export const UPDATE_WARENEMPFAENGER_LIST = 'UPDATE_WARENEMPFAENGER_LIST';
export const SET_ROLLE = 'SET_ROLLE';
export const ADD_WE = 'ADD_WE';
export const REMOVE_WE = 'REMOVE_WE';
export const ADD_DE = 'ADD_DE';
export const REMOVE_DE = 'REMOVE_DE';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCEEDED = 'DELETE_USER_SUCCEEDED';
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED';

export interface UpdateWEListAction {
    type: typeof UPDATE_WARENEMPFAENGER_LIST;
    partner: Partner[];
    debitor: Partner[];
}

export interface SetRolleAction {
    type: typeof SET_ROLLE;
    rolle: string;
}

export interface AddWEAction {
    type: typeof ADD_WE;
    partner: Partner[];
}

export interface RemoveWEAction {
    type: typeof REMOVE_WE;
    partnerNumer: string;
}

export interface AddDEAction {
    type: typeof ADD_DE;
    payload: Partner[];
}

export interface RemoveDEAction {
    type: typeof REMOVE_DE;
    payload: Partner;
}

export interface DeleteUserAction {
    type: typeof DELETE_USER;
    user: User;
    history: History<LocationState>;
}

export interface DeleteUserSucceededAction {
    type: typeof DELETE_USER_SUCCEEDED;
}

export interface DeleteUserFailedAction {
    type: typeof DELETE_USER_FAILED;
}

export function deleteUser(user: User, history: History<LocationState>): DeleteUserAction {
    return {
        type: DELETE_USER,
        user,
        history,
    };
}

export function deleteUserSucceeded(): DeleteUserSucceededAction {
    return {
        type: DELETE_USER_SUCCEEDED,
    };
}

export function deleteUserFailed(): DeleteUserFailedAction {
    return {
        type: DELETE_USER_FAILED,
    };
}

export interface GetUserAction {
    type: typeof GET_USER;
    registrierungsStatus: RegistrierungsStatus;
}

export interface GetUserSucceededAction {
    type: typeof GET_USER_SUCCEEDED;
    user: User[];
}

export interface GetUserFailedAction {
    type: typeof GET_USER_FAILED;
}

export interface UpdateUserAction {
    type: typeof UPDATE_USER;
    user: User;
    history: History<LocationState>;
}

export interface UpdateUserSucceededAction {
    type: typeof UPDATE_USER_SUCCEEDED;
    user: User;
}

export interface UpdateUserFailedAction {
    type: typeof UPDATE_USER_FAILED;
    user: User;
}

export function getUser(registrierungsStatus: RegistrierungsStatus): GetUserAction {
    return {
        type: GET_USER,
        registrierungsStatus,
    };
}

export function getUserSucceeded(user: User[]): GetUserSucceededAction {
    return {
        type: GET_USER_SUCCEEDED,
        user,
    };
}

export function getUserFailed(): GetUserFailedAction {
    return {
        type: GET_USER_FAILED,
    };
}

export function updateUser(user: User, history: History<LocationState>): UpdateUserAction {
    return {
        type: UPDATE_USER,
        user,
        history,
    };
}

export function updateUserSucceeded(user: User): UpdateUserSucceededAction {
    return {
        type: UPDATE_USER_SUCCEEDED,
        user,
    };
}

export function updateUserFailed(user: User): UpdateUserFailedAction {
    return {
        type: UPDATE_USER_FAILED,
        user,
    };
}

export interface GetUserDetailsAction {
    type: typeof GET_USER_DETAILS;
    userId: string;
}

export interface GetUserDetailsSucceededAction {
    type: typeof GET_USER_DETAILS_SUCCEEDED;
    user: User;
}

export interface GetUserDetailsFailedAction {
    type: typeof GET_USER_DETAILS_FAILED;
}

export function getUserDetails(userId: string): GetUserDetailsAction {
    return {
        type: GET_USER_DETAILS,
        userId,
    };
}

export function getUserDetailsSucceeded(user: User): GetUserDetailsSucceededAction {
    return {
        type: GET_USER_DETAILS_SUCCEEDED,
        user,
    };
}

export function getUserDetailsFailed(): GetUserDetailsFailedAction {
    return {
        type: GET_USER_DETAILS_FAILED,
    };
}

export type UserActions =
    | GetUserAction
    | GetUserSucceededAction
    | GetUserFailedAction
    | UpdateUserAction
    | UpdateUserFailedAction
    | UpdateUserSucceededAction
    | GetUserDetailsAction
    | GetUserDetailsSucceededAction
    | GetUserDetailsFailedAction
    | DeleteUserAction
    | DeleteUserFailedAction
    | DeleteUserSucceededAction
    | UpdateWEListAction
    | ResetPartnerSearch
    | AddWEAction
    | RemoveWEAction
    | AddDEAction
    | RemoveDEAction
    | SetRolleAction;

export type UserState = {
    user: User[];
    selectedUser: User;
    loadFailed: boolean;
    isLoading: boolean;
    loadFinished: boolean;
    loadDetailsFailed: boolean;
    isLoadingDetails: boolean;
    loadDetailsFinished: boolean;
    hasSubmitError: boolean;
    isSubmitting: boolean;
    isDeleting: boolean;
    hasDeleteError: boolean;
};

export const INITIAL_USER_STATE: UserState = {
    user: [],
    selectedUser: emptyUser,
    loadFailed: false,
    isLoading: false,
    loadFinished: false,
    loadDetailsFailed: false,
    isLoadingDetails: false,
    loadDetailsFinished: false,
    hasSubmitError: false,
    isSubmitting: false,
    isDeleting: false,
    hasDeleteError: false,
};

const selectUserState = (state: RootState): UserState => state.user;
const selectUser = (state: RootState): User[] => selectUserState(state).user;

export const UserSelectors = {
    user: selectUser,
    isLoading: (state: RootState): boolean => selectUserState(state).isLoading,
};

export function userReducer(state = INITIAL_USER_STATE, action: UserActions): UserState {
    switch (action.type) {
        case GET_USER:
            return {
                ...state,
                isLoading: true,
                loadFailed: false,
                loadFinished: false,
            };
        case GET_USER_SUCCEEDED:
            return {
                ...state,
                user: action.user,
                loadFailed: false,
                isLoading: false,
                loadFinished: true,
            };
        case GET_USER_FAILED:
            return {
                ...state,
                loadFailed: true,
                isLoading: false,
                loadFinished: true,
            };
        case UPDATE_USER:
            return {
                ...state,
                user: state.user.map((u) => (u.id === action.user.id ? action.user : u)),
                hasSubmitError: false,
                isSubmitting: true,
            };
        case UPDATE_USER_SUCCEEDED:
            return {
                ...state,
                user: state.user.map((u) => (u.id === action.user.id ? action.user : u)),
                hasSubmitError: false,
                isSubmitting: false,
            };
        case UPDATE_USER_FAILED:
            return {
                ...state,
                user: state.user.map((u) => (u.id === action.user.id ? action.user : u)),
                hasSubmitError: true,
                isSubmitting: false,
            };
        case GET_USER_DETAILS:
            return {
                ...state,
                selectedUser: emptyUser,
                isLoadingDetails: true,
                loadDetailsFailed: false,
                loadDetailsFinished: false,
            };
        case GET_USER_DETAILS_SUCCEEDED:
            return {
                ...state,
                selectedUser: action.user,
                loadDetailsFailed: false,
                isLoadingDetails: false,
                loadDetailsFinished: true,
            };
        case GET_USER_DETAILS_FAILED:
            return {
                ...state,
                loadDetailsFailed: true,
                isLoadingDetails: false,
                loadDetailsFinished: true,
            };
        case DELETE_USER:
            return {
                ...state,
                isDeleting: true,
                hasDeleteError: false,
            };
        case DELETE_USER_FAILED:
            return {
                ...state,
                isDeleting: false,
                hasDeleteError: true,
            };
        case DELETE_USER_SUCCEEDED:
            return {
                ...state,
                isDeleting: false,
                hasDeleteError: false,
            };
        case UPDATE_WARENEMPFAENGER_LIST:
            const currentUser = { ...state.selectedUser };
            const newWarenEmpfaenger = currentUser.warenEmpfaengerListe?.filter((p) =>
                action.partner.some((w) => p.partnerNummer == w.partnerNummer)
            );
            currentUser.warenEmpfaengerListe = newWarenEmpfaenger;
            return {
                ...state,
                selectedUser: currentUser,
            };
        case 'RESET_ALL_PARTNERS_ACTION':
            return { ...state, selectedUser: { ...state.selectedUser, partnerListe: [], warenEmpfaengerListe: [] } };
        case ADD_WE:
            return {
                ...state,
                selectedUser: {
                    ...state.selectedUser,
                    warenEmpfaengerListe: action.partner,
                },
            };
        case 'REMOVE_WE':
            return {
                ...state,
                selectedUser: {
                    ...state.selectedUser,
                    warenEmpfaengerListe:
                        state.selectedUser.warenEmpfaengerListe?.filter((p) => p.partnerNummer != action.partnerNumer) ?? [],
                },
            };
        case ADD_DE:
            console.log(action);
            return {
                ...state,
                selectedUser: {
                    ...state.selectedUser,
                    partnerListe: action.payload,
                },
            };
        case REMOVE_DE:
            return {
                ...state,
                selectedUser: {
                    ...state.selectedUser,
                    partnerListe: state.selectedUser.partnerListe?.filter((p) => p.partnerNummer != action.payload.partnerNummer) ?? [],
                },
            };
        case 'SET_ROLLE':
            const newUser = { ...state.selectedUser };
            if (action.rolle != Rollen.FARMLEITER && newUser.rolle == Rollen.FARMLEITER) {
                if (action.rolle == Rollen.DEBITOR) newUser.partnerListe = [...newUser.partnerListe];
                if (action.rolle == Rollen.RECIPIENT) newUser.partnerListe = [...newUser.warenEmpfaengerListe!];
                newUser.warenEmpfaengerListe = [];
            } else if (action.rolle == Rollen.FARMLEITER && newUser.rolle != Rollen.FARMLEITER) {
                newUser.partnerListe = [];
                newUser.warenEmpfaengerListe = [];
            }
            newUser.rolle = action.rolle;
            return {
                ...state,
                selectedUser: newUser,
            };
        default:
            return state;
    }
}
