import { BestellpositionArtikelLoseWareView } from '../../../../types';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { selectIsDebitor, selectIsFarmleiter } from '../../../../../store/Navigation.store';
import { FieldArrayRenderProps } from 'formik';
import { TableRow } from '../../../../ui-components/Table';
import { BezeichnungEntry, DeleteEntry, MengeEntry, PreisartEntry, SiloEntry, WerkeEntry, ZulagenEntry } from '../../PropertyEntries';

type Props = {
    bestellposition: BestellpositionArtikelLoseWareView;
    arrayHelpers: FieldArrayRenderProps;
    onArtikelDeleted?: (artikelNummer: string) => void;
};

export const LoseWareTableRowNewBestellung = ({ bestellposition, arrayHelpers, onArtikelDeleted }: Props): JSX.Element => {
    const isDebitor = useSelector(selectIsDebitor);
    const isFarmleiter = useSelector(selectIsFarmleiter);
    return (
        <Fragment key={bestellposition.nummer}>
            <TableRow>
                <BezeichnungEntry.Cell bestellposition={bestellposition} />
                <ZulagenEntry.Cell bestellposition={bestellposition} />
                <WerkeEntry.Cell bestellposition={bestellposition} />
                {isDebitor || isFarmleiter ? <PreisartEntry.Cell bestellposition={bestellposition} /> : null}
                <SiloEntry.Cell bestellposition={bestellposition} />
                <MengeEntry.Cell bestellposition={bestellposition} />
                <DeleteEntry.Delete.Cell
                    bestellposition={bestellposition}
                    arrayHelpers={arrayHelpers}
                    onArtikelDeleted={onArtikelDeleted}
                    showConfirmDelete={bestellposition.menge > '0'}
                />
            </TableRow>
            <ZulagenEntry.Row bestellposition={bestellposition} />
        </Fragment>
    );
};
