import { ReactElement, useEffect, useState } from 'react';
import styles from './Bestellzusammenfassung.module.scss';
import formStyles from '../../shared/ui-components/Form/Form.module.scss';
import { SackwareListReadonly } from '../../shared/content-components/Bestellpositionen/ArtikelSackwareTable/SackwareListReadonly';
import { SackwareTableReadonly } from '../../shared/content-components/Bestellpositionen/ArtikelSackwareTable/SackwareTableReadonly';
import { LoseWareListReadonly } from '../../shared/content-components/Bestellpositionen/ArtikelLoseWareTable/LoseWareListReadonly';
import { LoseWareTableReadonly } from '../../shared/content-components/Bestellpositionen/ArtikelLoseWareTable/LoseWareTableReadonly';
import ButtonGroup from '../../shared/ui-components/ButtonGroup/ButtonGroup';
import Button from '../../shared/ui-components/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
    routes,
    selectBestellprozesstyp,
    selectNextRoute,
    selectPreviousRoute,
    setCurrentWorkflowStep,
    WorkflowStep,
} from '../../store/Workflow.store';
import { getWerke } from '../../store/Abholwerk.store';
import { Link, useHistory } from 'react-router-dom';
import NonFormikCheckbox from '../../shared/ui-components/CheckBox/NonFormikCheckbox';
import NotificationBar from '../../shared/ui-components/NotificationBar/NotificationBar';
import {
    BestellungSelectors,
    selectKfzKennzeichenFromBestellung,
    selectSpeditionFromBestellung,
    sendBestellung,
} from '../../store/Bestellung.store';
import { getWarenempfaenger } from '../../store/Warenempfaenger.store';
import IconButton from '../../shared/ui-components/IconButton/IconButton';
import iconEdit from '../../assets/icon-pencil.svg';
import { formatVvvoNummer } from '../../shared/helper/vvvoNummern-helper';
import { getRechnungsempfaenger } from '../../store/Rechnungsempfaenger.store';
import FeatureToggle from '../../shared/ui-components/FeatureToggle/FeatureToggle';
import { getWunschterminDatumText, getWunschterminZeitraumDisplayText } from '../../shared/helper/date-helper';
import { BESTELLEN_BUTTON_TEXT, BESTELLUNG_MAX_WEIGHT_IN_KG, OVERWEIGHT_ERROR } from '../../shared/constants';
import Formulas from '../../shared/formulas';
import { useMediaQuery } from 'react-responsive';
import type { RootState } from '../../configureStore';
import useDocumentTitle from '../../shared/hooks/useDocumentTitle';
import { Bestellprozesstyp, Lieferbedingungen, LieferbedingungLabel, Severity } from '../../shared/types/enums';
import { selectIsDebitor, selectIsFarmleiter } from '../../store/Navigation.store';
import { useFetchKfzKennzeichen } from '../../shared/hooks/useFetchKfzKennzeichen';
import { useFetchWerkeForArtikel } from '../../shared/hooks/useFetchWerke';
import { useFetchArtikelLoseWare } from '../../shared/hooks/useFetchArtikelLoseWare';
import { RechnungsempfaengerErrorNotification } from '../../shared/content-components/Notifications/RechnungsempfaengerErrorNotification';
import { WarenempfaengerErrorNotification } from '../../shared/content-components/Notifications/WarenempfaengerErrorNotification';
import { FormColumn } from '../../shared/ui-components/Form/FormColumn';
import { FormRow } from '../../shared/ui-components/Form/FormRow';
import { kfzKennzeichenSelectors } from '../../store/KfzKennzeichen.store';
import { useRechnungsempfaengerFromBestellung } from '../../shared/hooks/useRechnungsempfaengerFromBestellung';
import { useFetchArtikelSackwaren } from '../../shared/hooks/useFetchArtikelSackwaren';
import { useWarenempfaengerFromBestellung } from '../../shared/hooks/useWarenempfaengerFromBestellung';
import { speditionSelectors } from 'store/Spedition.store';

const Bestellzusammenfassung = (): ReactElement => {
    const history = useHistory();
    const dispatch = useDispatch();
    useDocumentTitle('Bestellzusammenfassung');

    const bestellpositionenView = useSelector(BestellungSelectors.bestellpositionenViewFromBestellpositionenAndArtikeln);
    const bestellpositionenLoseWareView = useSelector(BestellungSelectors.bestellpositionenLoseWareView);
    const bestellpositionenSackwareView = useSelector(BestellungSelectors.bestellpositionenSackwareView);
    const bestellpositionenViewNummern = useSelector(BestellungSelectors.bestellpositionenViewNummern);
    const allKfzKennzeichen = useSelector(kfzKennzeichenSelectors.allKfzKennzeichen);
    const selectedKfzKennzeichen = useSelector((state: RootState) => selectKfzKennzeichenFromBestellung(state, allKfzKennzeichen));
    const allSpeditionen = useSelector(speditionSelectors.allSpeditionen);
    const selectedSpedition = useSelector((state: RootState) => selectSpeditionFromBestellung(state, allSpeditionen));
    const {
        lieferbedingung,
        ansprechpartner,
        bestellhinweis,
        bestellhinweisWE,
        lieferhinweis,
        wunschtermin,
        wunschterminBis,
        wunschterminVon,
        vvvoNummer,
        selectedWerkId,
    } = useSelector(BestellungSelectors.bestellung);
    const rechnungsempfaenger = useRechnungsempfaengerFromBestellung();
    const warenempfaenger = useWarenempfaengerFromBestellung();
    const nextRoute = useSelector(selectNextRoute);
    const previousRoute = useSelector(selectPreviousRoute);
    const bestellprozesstyp = useSelector(selectBestellprozesstyp) || Bestellprozesstyp.Nutztierfutter;
    const formattedVvvoNummer = formatVvvoNummer(vvvoNummer || '');
    const isDebitor = useSelector(selectIsDebitor);
    const isFarmleiter = useSelector(selectIsFarmleiter);
    const [overWeightConfirmed, setOverWeightConfirmed] = useState(false);
    const [hasOverWeight, setHasOverWeight] = useState(false);
    const isMobile = useMediaQuery({ query: `(max-width: ${parseInt(styles['breakpoint-tablet-portrait-up']) - 1 + 'px'})` });

    const notificationSeverity = hasOverWeight && overWeightConfirmed ? Severity.CONFIRMRED : Severity.ERROR;
    const bestellpositionenRoute =
        bestellprozesstyp === Bestellprozesstyp.Nutztierfutter ? routes.artikelNutztier : routes.artikelHeimtierfutter;

    useEffect(() => {
        const totalWeightLW = (bestellpositionenLoseWareView || []).reduce((acc, bp) => {
            return acc + Formulas.mengeToWeightInKg(bp.menge);
        }, 0);

        const totalWeightSW = bestellpositionenSackwareView.reduce((acc, bp) => {
            return acc + Formulas.mengePalAndVeToWeightInKg(bp.mengePal, bp.mengeVe, bp.faktorBasiseinheitPal, bp.faktorBasiseinheitVe);
        }, 0);

        const totalWeight = totalWeightLW + totalWeightSW;
        setHasOverWeight(totalWeight > BESTELLUNG_MAX_WEIGHT_IN_KG);
    }, [bestellpositionenLoseWareView, bestellpositionenSackwareView]);

    useEffect(() => {
        dispatch(setCurrentWorkflowStep(WorkflowStep.Bestellzusammenfassung, history));
        dispatch(getWarenempfaenger());
        dispatch(getRechnungsempfaenger());
    }, [dispatch, history]);

    useFetchKfzKennzeichen();
    useFetchArtikelLoseWare(rechnungsempfaenger?.partnerNummer, warenempfaenger?.partnerNummer, bestellprozesstyp);
    useFetchArtikelSackwaren(rechnungsempfaenger?.partnerNummer, warenempfaenger?.partnerNummer, bestellprozesstyp, selectedWerkId);
    useFetchWerkeForArtikel(bestellpositionenViewNummern, rechnungsempfaenger?.partnerNummer);

    useEffect(() => {
        if (!rechnungsempfaenger?.partnerNummer) {
            return;
        }

        if (isDebitor || isFarmleiter) {
            dispatch(getWerke(rechnungsempfaenger?.partnerNummer));
        } else {
            if (warenempfaenger?.partnerNummer) {
                dispatch(getWerke(rechnungsempfaenger?.partnerNummer, warenempfaenger?.partnerNummer));
            }
        }
    }, [dispatch, rechnungsempfaenger?.partnerNummer, isDebitor, isFarmleiter, warenempfaenger?.partnerNummer]);

    const wunschterminText =
        isDebitor || isFarmleiter
            ? getWunschterminDatumText(wunschtermin)
            : getWunschterminZeitraumDisplayText(wunschterminVon, wunschterminBis);

    const keineBestellpositionen = bestellpositionenView.length === 0;
    return (
        <>
            <h2>Bestell&shy;zusammenfassung</h2>
            <RechnungsempfaengerErrorNotification />
            <WarenempfaengerErrorNotification />
            <NotificationBar
                testId="bestellpositionen-validation-message-bar"
                message=""
                disableScrolling
                isVisible={keineBestellpositionen}
            >
                <Link
                    className={styles._notificationLink}
                    to={bestellprozesstyp === Bestellprozesstyp.Heimtierfutter ? routes.artikelHeimtierfutter : routes.artikelNutztier}
                >
                    Bitte wählen Sie erst Artikel für Ihre Bestellung aus.
                </Link>
            </NotificationBar>
            <NotificationBar
                testId="weight-validation-message-bar"
                message=""
                disableScrolling
                isVisible={hasOverWeight}
                severity={notificationSeverity}
            >
                <NonFormikCheckbox
                    id="notificationCheck"
                    name="notificationCheck"
                    label={OVERWEIGHT_ERROR}
                    onFieldChange={(checked): void => {
                        setOverWeightConfirmed(checked);
                    }}
                />
            </NotificationBar>
            <FormRow>
                <FormColumn isSection>
                    <div className={formStyles._sectionHeader}>
                        <h3>Warenempfänger</h3>
                        <IconButton icon={iconEdit} alt="bearbeiten" onClick={(): void => history.push(routes.warenempfaengerAuswaehlen)} />
                    </div>
                    <>
                        <div>{warenempfaenger?.hauptname}</div>
                        <div>{warenempfaenger?.strasse}</div>
                        <div className={styles._plzOrt}>
                            {warenempfaenger?.postleitzahl} {warenempfaenger?.ort}
                        </div>
                        {formattedVvvoNummer && (
                            <>
                                <br />
                                <div>VVVO-Nummer: {formattedVvvoNummer}</div>
                            </>
                        )}
                    </>
                </FormColumn>
                <FormColumn isSection>
                    <div className={formStyles._sectionHeader}>
                        <h3>{isDebitor || isFarmleiter ? 'Rechnungsempfänger' : 'Landwarenhandel'}</h3>
                        <IconButton
                            icon={iconEdit}
                            alt="bearbeiten"
                            onClick={(): void => history.push(routes.rechnungsempfaengerAuswaehlen)}
                        />
                    </div>
                    <>
                        <div>{rechnungsempfaenger?.hauptname}</div>
                        <div>{rechnungsempfaenger?.strasse}</div>
                        <div className={styles._plzOrt}>
                            {rechnungsempfaenger?.postleitzahl} {rechnungsempfaenger?.ort}
                        </div>
                    </>
                </FormColumn>
            </FormRow>
            {(isDebitor || isFarmleiter) && (
                <FormRow>
                    <FormColumn isSection>
                        <div className={formStyles._sectionHeader}>
                            <h3>Lieferart</h3>
                            <IconButton icon={iconEdit} alt="bearbeiten" onClick={(): void => history.push(routes.bestelldaten)} />
                        </div>
                        <div>
                            {lieferbedingung === Lieferbedingungen.EX_WERK ? LieferbedingungLabel.EX_WERK : LieferbedingungLabel.FRANCO}
                        </div>
                    </FormColumn>
                    <FormColumn isSection>
                        <div className={formStyles._sectionHeader}>
                            <h3>Kontakt für Rückfragen</h3>
                            <IconButton icon={iconEdit} alt="bearbeiten" onClick={(): void => history.push(routes.bestelldaten)} />
                        </div>
                        <div>{ansprechpartner}</div>
                    </FormColumn>
                </FormRow>
            )}
            <FormRow>
                {(bestellhinweis || bestellhinweisWE) && (
                    <FormColumn isSection>
                        <div className={formStyles._sectionHeader}>
                            <h3>Bestellhinweis</h3>
                            <IconButton icon={iconEdit} alt="bearbeiten" onClick={(): void => history.push(routes.bestelldaten)} />
                        </div>
                        <div>{isDebitor || isFarmleiter ? bestellhinweis : bestellhinweisWE}</div>
                    </FormColumn>
                )}
                {lieferhinweis && (
                    <FormColumn isSection>
                        <div className={formStyles._sectionHeader}>
                            <h3>Lieferhinweis</h3>
                            <IconButton icon={iconEdit} alt="bearbeiten" onClick={(): void => history.push(routes.bestelldaten)} />
                        </div>
                        <div>{lieferhinweis}</div>
                    </FormColumn>
                )}
                {(!bestellhinweis || !lieferhinweis) && <FormColumn isSection />}
            </FormRow>
            <FormRow>
                {selectedKfzKennzeichen && (
                    <FormColumn isSection>
                        <div className={formStyles._sectionHeader}>
                            <h3>KFZ-Kennzeichen</h3>
                            <IconButton icon={iconEdit} alt="bearbeiten" onClick={(): void => history.push(routes.bestelldaten)} />
                        </div>
                        <div>{selectedKfzKennzeichen}</div>
                    </FormColumn>
                )}
                <FormColumn isSection>
                    <div className={formStyles._sectionHeader}>
                        <h3>Wunschtermin</h3>
                        <IconButton
                            dataCy={'workflow-go-to-wunschtermin'}
                            icon={iconEdit}
                            alt="bearbeiten"
                            onClick={(): void => {
                                if (bestellprozesstyp === Bestellprozesstyp.Nutztierfutter) {
                                    history.push(routes.artikelNutztier);
                                } else {
                                    history.push(routes.bestelldaten);
                                }
                            }}
                        />
                    </div>
                    <div>{wunschterminText}</div>
                </FormColumn>
                {selectedSpedition && (
                    <FormColumn isSection>
                        <div className={formStyles._sectionHeader}>
                            <h3>Spediteur</h3>
                            <IconButton icon={iconEdit} alt="bearbeiten" onClick={(): void => history.push(routes.bestelldaten)} />
                        </div>
                        <div>{selectedSpedition}</div>
                    </FormColumn>
                )}
                {(!selectedKfzKennzeichen || !selectedSpedition || !wunschterminText) && <FormColumn isSection />}
                <FormRow></FormRow>
            </FormRow>
            {bestellpositionenSackwareView.length > 0 && (
                <>
                    <div className={formStyles._sectionHeader}>
                        <h3>Bestellpositionen Sackware</h3>
                        <IconButton icon={iconEdit} alt="bearbeiten" onClick={(): void => history.push(bestellpositionenRoute)} />
                    </div>
                    {isMobile ? (
                        <SackwareListReadonly bestellpositionenView={bestellpositionenSackwareView} />
                    ) : (
                        <SackwareTableReadonly bestellpositionen={bestellpositionenSackwareView} />
                    )}
                </>
            )}

            {bestellpositionenLoseWareView.length > 0 && (
                <>
                    <div className={formStyles._sectionHeader}>
                        <h3>Bestellpositionen lose Ware</h3>
                        <IconButton icon={iconEdit} alt="bearbeiten" onClick={(): void => history.push(bestellpositionenRoute)} />
                    </div>
                    {isMobile ? (
                        <LoseWareListReadonly bestellpositionen={bestellpositionenLoseWareView} />
                    ) : (
                        <LoseWareTableReadonly bestellpositionen={bestellpositionenLoseWareView} />
                    )}
                </>
            )}
            {isDebitor || isFarmleiter ? (
                <ButtonGroup>
                    <Button type="button" onClick={(): void => history.push(previousRoute)} isSecondary={true}>
                        Zurück
                    </Button>
                    <FeatureToggle featureName="DEBITOR_DRAFT_BESTELLUNG">
                        <Button
                            isSecondary={true}
                            type="button"
                            onClick={(): void => {
                                dispatch(sendBestellung({ isDraft: true }));
                                history.push(nextRoute + '/auftrag');
                            }}
                            disabled={!overWeightConfirmed && hasOverWeight}
                        >
                            Zum Auftragskorb verschieben
                        </Button>
                    </FeatureToggle>
                    <Button
                        type="button"
                        data-cy="workflow-order"
                        onClick={(): void => {
                            dispatch(sendBestellung({ isDraft: false }));
                            history.push(nextRoute + '/bestellung');
                        }}
                        disabled={!overWeightConfirmed && hasOverWeight}
                    >
                        {BESTELLEN_BUTTON_TEXT}
                    </Button>
                </ButtonGroup>
            ) : (
                <ButtonGroup>
                    <Button type="button" onClick={(): void => history.push(previousRoute)} isSecondary={true}>
                        Zurück
                    </Button>
                    <Button
                        type="button"
                        data-cy="workflow-order"
                        onClick={(): void => {
                            dispatch(sendBestellung({ isDraft: true }));
                            history.push(nextRoute + '/bestellung');
                        }}
                        disabled={!overWeightConfirmed && hasOverWeight}
                    >
                        {BESTELLEN_BUTTON_TEXT}
                    </Button>
                </ButtonGroup>
            )}
        </>
    );
};

export default Bestellzusammenfassung;
