import { Route, Router, Switch } from 'react-router-dom';
import { History } from 'history';
import * as Sentry from '@sentry/react';
import MobileDetect from 'mobile-detect';
import { Provider } from 'react-redux';
import { compose, Store } from 'redux';
import Shell from './shared/ui-components/Shell/Shell';
import ScrollToTop from './shared/ui-components/ScrollToTop/ScrollToTop';
import StartseiteInteressent from './views/StartseiteInteressent/StartseiteInteressent';
import { generateNavigationEntries } from './store/Navigation.store';
import Startseite from './views/Startseite/Startseite';
import { RootState, store } from './configureStore';
import './App.module.scss';
import { Rollen } from './shared/types/enums';
import type { CurrentUser } from './shared/types';
import Keycloak from 'keycloak-js';
import {
    adminRouten,
    appRouten,
    createRoute,
    debitorRouten,
    farmleiterRouten,
    prospectRouten,
    RouteConfig,
    warenempfaengerRouten,
} from './routeDefinitions';
import Config from './shared/config';
import { ReactElement, useEffect, useMemo } from 'react';
import FlutterBridge from './flutter/flutterBridge';

const { isApp } = Config.getConfig();

declare global {
    interface Window {
        isMobile: string | null;
        store: Store<RootState>;
        __store__: Store<RootState>;
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;

        // Flutter Channels
        DownloadChannel: MessagePort | undefined;
        LicenseChannel: MessagePort | undefined;
        NachrichtChannel: MessagePort | undefined;
        FrontendLifecycleChannel: MessagePort | undefined;
        TokenChannel: MessagePort | undefined;
        getToken: () => string | null;
    }
}

const md = new MobileDetect(window.navigator.userAgent);
window.isMobile = md.mobile();

window.getToken = () => {
    if (isApp()) {
        return window.sessionStorage.getItem('authentication');
    }
    return null;
};

type Props = {
    keycloak: Partial<Keycloak>;
    currentUser: CurrentUser;
    history: History<unknown>;
};

const SentryRoute = Sentry.withSentryRouting(Route);

function App(props: Props): ReactElement {
    const { currentUser, history } = props;
    const isAdmin = currentUser.roles.includes(Rollen.ADMIN);
    const isDebitor = currentUser.roles.includes(Rollen.DEBITOR);
    const isFarmleiter = currentUser.roles.includes(Rollen.FARMLEITER);
    const isWarenempfaenger = currentUser.roles.includes(Rollen.RECIPIENT);
    const isProspect = currentUser.roles.includes(Rollen.PROSPECT);

    const routeDefinitions = useMemo((): RouteConfig[] => {
        const routeDefinitions: RouteConfig[] = [];
        if (isApp()) {
            routeDefinitions.push(...appRouten);
        }

        if (isDebitor) {
            routeDefinitions.push(...debitorRouten);
        }

        if (isFarmleiter) {
            routeDefinitions.push(...farmleiterRouten);
        }

        if (isWarenempfaenger) {
            routeDefinitions.push(...warenempfaengerRouten);
        }

        if (isAdmin) {
            routeDefinitions.push(...adminRouten);
        }

        if (isProspect) {
            routeDefinitions.push(...prospectRouten, createRoute('/', StartseiteInteressent));
        } else {
            routeDefinitions.push(createRoute('/', Startseite));
        }

        return [...routeDefinitions];
    }, [isAdmin, isProspect, isDebitor, isWarenempfaenger, isFarmleiter]);

    console.log(currentUser.roles);

    store.dispatch(generateNavigationEntries(currentUser.roles as Rollen[], store.getState()?.workflow?.currentWorkflowStep));

    useEffect(() => {
        const onPageLoad = () => {
            FlutterBridge.sendLifecycleState(true);
        };

        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad, false);
            return () => {
                window.removeEventListener('load', onPageLoad);
                FlutterBridge.sendLifecycleState(false);
            };
        }
        return () => {
            FlutterBridge.sendLifecycleState(false);
        };
    }, []);

    return (
        <Provider store={store}>
            <Router history={history}>
                <ScrollToTop />
                <Shell keycloak={props.keycloak} currentUser={currentUser}>
                    <Switch>
                        {routeDefinitions.map((definition, index) => (
                            <SentryRoute key={index} path={definition.path} component={definition.component} />
                        ))}
                    </Switch>
                </Shell>
            </Router>
        </Provider>
    );
}

export default App;
