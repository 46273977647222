import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BestelluebersichtSelectors } from '../../store/Bestelluebersicht.store';
import LoadingSpinner from '../../shared/ui-components/LoadingSpinner/LoadingSpinner';
import useDocumentTitle from '../../shared/hooks/useDocumentTitle';
import { selectIsDebitor, selectIsFarmleiter } from '../../store/Navigation.store';
import { FormRow } from '../../shared/ui-components/Form/FormRow';
import NotificationBar from '../../shared/ui-components/NotificationBar/NotificationBar';
import type { BestellungParam } from '../../shared/types';
import { FormColumn } from '../../shared/ui-components/Form/FormColumn';
import { BestellstatusTable } from './BestellstatusTable';
import { BestellstatusGuard } from '../../shared/guards/BestellstatusGuard';
import { BestellansichtAenderbar } from './BestellansichtAenderbar';
import { BestellansichtGesperrt } from './BestellansichtGesperrt';
import { LinkToLieferungen } from './LinkToLieferungen';
import { useFetchBestellung } from '../../shared/hooks/useFetchBestellung';

const Bestellansicht = (): JSX.Element => {
    const { bestellnummer } = useParams<BestellungParam>();
    useDocumentTitle(`Bestellung ${bestellnummer}`);
    const bestellung = useSelector(BestelluebersichtSelectors.currentBestellung);
    const isLoading = useSelector(BestelluebersichtSelectors.currentBestellungLoading);
    const loadFailed = useSelector(BestelluebersichtSelectors.currentBestellungLoadFailed);
    const isDebitor = useSelector(selectIsDebitor);
    const isFarmleiter = useSelector(selectIsFarmleiter);

    const reloadBestellung = useFetchBestellung(bestellnummer);

    return (
        <>
            <NotificationBar
                testId="loadError-message-bar"
                message="Die Bestellung konnte nicht geladen werden. Bitte versuchen Sie es noch einmal."
                isVisible={loadFailed}
                actionText="Neu laden"
                actionCallback={reloadBestellung}
            />
            <h2>Bestellung {bestellnummer}</h2>
            <FormRow>
                <FormColumn isSection>
                    <BestellstatusTable />
                </FormColumn>
                {bestellung.geschlossen ? (
                    <FormColumn isSection />
                ) : (
                    <FormColumn isSection>
                        <LinkToLieferungen bestellnummer={bestellnummer} />
                    </FormColumn>
                )}
            </FormRow>
            <LoadingSpinner isLoading={isLoading}>
                {isDebitor || isFarmleiter ? (
                    <BestellstatusGuard
                        beiDTC={<BestellansichtAenderbar />}
                        beimLandhandel={<BestellansichtGesperrt />}
                        aenderungswunsch={<BestellansichtGesperrt />}
                        vomLandhandelStorniert={<BestellansichtGesperrt />}
                    />
                ) : (
                    <BestellstatusGuard
                        beimLandhandel={bestellung.isDraft ? <BestellansichtAenderbar /> : <BestellansichtGesperrt />}
                        beiDTC={<BestellansichtGesperrt />}
                        vomLandhandelStorniert={<BestellansichtGesperrt />}
                        aenderungswunsch={<BestellansichtGesperrt />}
                        vomWarenempfaengerStorniert={<BestellansichtGesperrt />}
                    />
                )}
            </LoadingSpinner>
        </>
    );
};

export default Bestellansicht;
