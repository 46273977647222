import { BestellpositionArtikelSackwareView } from '../../../types';
import tableStyles from '../../../ui-components/Table/Table.module.scss';
import styles from './SackwareTable.module.scss';
import { TableHeaderCell, TableRow } from '../../../ui-components/Table';
import { selectIsDebitor, selectIsFarmleiter } from '../../../../store/Navigation.store';
import { useSelector } from 'react-redux';
import { BezeichnungEntry, MengeSWEntry, WerkeEntry } from '../PropertyEntries';
import { Breakpoint, useBreakpoint } from '../../../hooks/useBreakpoint';

export type SackwareTableReadonlyProps = Readonly<{
    bestellpositionen: BestellpositionArtikelSackwareView[];
}>;

export const SackwareTableReadonly = ({ bestellpositionen }: SackwareTableReadonlyProps): JSX.Element => {
    const isDebitor = useSelector(selectIsDebitor);
    const isFarmleiter = useSelector(selectIsFarmleiter);
    const isTabletPortrait = useBreakpoint(Breakpoint.TABLET_PORTRAIT);

    return (
        <table className={[tableStyles._table, styles._positionenTable].join(' ')}>
            <thead>
                <tr className={tableStyles._tableHeaderRow}>
                    {isTabletPortrait ? (
                        <TableHeaderCell text additionalStyles={[styles._artikelnr]}>
                            Artikelnr.
                        </TableHeaderCell>
                    ) : null}
                    <TableHeaderCell text additionalStyles={[styles._artikelBezeichnung]}>
                        Artikel
                    </TableHeaderCell>
                    {(isDebitor || isFarmleiter) && <TableHeaderCell text>Werk</TableHeaderCell>}
                    <TableHeaderCell number>VE</TableHeaderCell>
                    <TableHeaderCell number>kg</TableHeaderCell>
                    <TableHeaderCell number>Pal.</TableHeaderCell>
                    <TableHeaderCell number>kg / Pal.</TableHeaderCell>
                    <TableHeaderCell number>Gesamt VE</TableHeaderCell>
                </tr>
            </thead>
            <tbody>
                {bestellpositionen.map((bestellposition, index) => {
                    return (
                        <TableRow key={index}>
                            <BezeichnungEntry.Cell bestellposition={bestellposition} readonly />
                            <WerkeEntry.Cell bestellposition={bestellposition} readonly />
                            <MengeSWEntry.Cell bestellposition={bestellposition} readonly />
                        </TableRow>
                    );
                })}
            </tbody>
        </table>
    );
};
