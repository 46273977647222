import { BestellpositionArtikelLoseWareView } from '../../../../types';
import { FieldArrayRenderProps } from 'formik';
import { useSelector } from 'react-redux';
import { selectIsDebitor, selectIsFarmleiter } from '../../../../../store/Navigation.store';
import styles from '../LoseWareList.module.scss';
import { AenderbarBisListEntry } from '../../../AenderbarBis/AenderbarBisListEntry';
import { BestelluebersichtSelectors } from '../../../../../store/Bestelluebersicht.store';
import {
    BezeichnungEntry,
    DeleteEntry,
    MengeEntry,
    ModifikationsstatusEntry,
    PreisartEntry,
    SiloEntry,
    WerkeEntry,
    WunschterminEntry,
    ZulagenEntry,
} from '../../PropertyEntries';
import { isExistingBestellposition } from '../../../../helper/bestellungen-helper';

type LoseWareListEntryProps = {
    bestellposition: BestellpositionArtikelLoseWareView;
    arrayHelpers: FieldArrayRenderProps;
    onArtikelDeleted?: (artikelNummer: string) => void;
};

export const LoseWareListEntryExistingBestellung = ({
    bestellposition,
    arrayHelpers,
    onArtikelDeleted,
}: LoseWareListEntryProps): JSX.Element => {
    const isDebitor = useSelector(selectIsDebitor);
    const isFarmleiter = useSelector(selectIsFarmleiter);
    const { storno, index, menge, nummer, positionsnummer } = bestellposition;

    const aenderbarBisExpired = useSelector(BestelluebersichtSelectors.hasAenderbarBisExpiredForPosition(nummer, positionsnummer));
    const existingBestellposition = isExistingBestellposition(bestellposition);
    const isCurrentPositionGesperrt = useSelector(BestelluebersichtSelectors.isCurrentPositionGesperrt(nummer, positionsnummer));

    const isDisabled = isCurrentPositionGesperrt || aenderbarBisExpired;
    return (
        <div className={styles._artikelContainer} key={index}>
            <div className={styles._headerContainer}>
                <BezeichnungEntry.List bestellposition={bestellposition} disabled={isDisabled} replaceable={isDebitor || isFarmleiter} />
                {existingBestellposition ? (
                    <DeleteEntry.Storno.List bestellposition={bestellposition} disabled={isDisabled} />
                ) : (
                    <DeleteEntry.Delete.List
                        bestellposition={bestellposition}
                        arrayHelpers={arrayHelpers}
                        disabled={isDisabled}
                        onArtikelDeleted={onArtikelDeleted}
                        showConfirmDelete={menge > '0'}
                    />
                )}
            </div>
            <div className={styles._modifikationsContainer}>
                <ModifikationsstatusEntry.List nummer={nummer} positionsnummer={positionsnummer} withText />
                <AenderbarBisListEntry
                    nummer={nummer}
                    positionsnummer={positionsnummer}
                    aenderbarBisExpired={aenderbarBisExpired}
                    storno={storno}
                />
            </div>
            <div className={styles._amountsContainer}>
                <MengeEntry.List bestellposition={bestellposition} disabled={isDisabled} />
                <ZulagenEntry.ListCheckbox bestellposition={bestellposition} disabled={isDisabled} />
            </div>
            <ZulagenEntry.ListText bestellposition={bestellposition} disabled={isDisabled} />
            {(isDebitor || isFarmleiter) && existingBestellposition ? (
                <WunschterminEntry.List bestellposition={bestellposition} disabled={bestellposition.storno || isDisabled} />
            ) : null}
            <div className={styles._siloWerk}>
                <SiloEntry.List bestellposition={bestellposition} disabled={isDisabled} />
                <WerkeEntry.List bestellposition={bestellposition} disabled={isDisabled} />
            </div>
            {isDebitor || isFarmleiter ? <PreisartEntry.List bestellposition={bestellposition} disabled={isDisabled} /> : null}
        </div>
    );
};
