import { Fragment, FunctionComponent, KeyboardEvent, ReactElement, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { CellProps, Column, SortingRule, usePagination, useSortBy, useTable } from 'react-table';
import { Dispatch } from 'redux';
import { getLieferungen } from '../../store/Lieferung.store';
import { BestellungDetails, BestellungParam, Filter, Lieferung, Rechnungsempfaenger, Warenempfaenger } from '../../shared/types';
import TextItem from '../../shared/ui-components/TextItem/TextItem';
import { EditCellProps } from '../../admin-views/UserTable/UserTable';
import { routes } from '../../store/Workflow.store';
import iconDetailsLink from '../../assets/icon-details-link.svg';
import NotificationBar from '../../shared/ui-components/NotificationBar/NotificationBar';
import LoadingSpinner from '../../shared/ui-components/LoadingSpinner/LoadingSpinner';
import { OverviewList } from '../../shared/ui-components/OverviewList/OverviewList';
import { OverviewTable } from '../../shared/ui-components/OverviewTable/OverviewTable';
import { OverviewPagination } from '../../shared/ui-components/OverviewPagination/OverviewPagination';
import {
    fakturierungsstatusToIcon,
    fakturierungsstatusToText,
    lieferstatusToIcon,
    lieferstatusToText,
} from '../Lieferungsansicht/Lieferungsansicht';
import Tippy from '@tippyjs/react';
import { convertDateWithTimeFromIsoStringToDateInfo } from '../../shared/helper/date-helper';
import { localStorageWrapper } from '../../localStorage';
import type { RootState } from '../../configureStore';
import useDocumentTitle from '../../shared/hooks/useDocumentTitle';
import localStyles from './Lieferungsuebersicht.module.scss';
import styles from '../BestelluebersichtUndAuftragskorb/BestelluebersichtUndAuftragskorb.module.scss';
import { selectIsDebitor, selectIsFarmleiter } from '../../store/Navigation.store';
import { Order } from '../../shared/types/enums';
import Select, { components, OptionTypeBase, SingleValueProps, ValueType } from 'react-select';
import { Option } from '../../shared/helper/select-options';
import { getWarenempfaenger } from '../../store/Warenempfaenger.store';
import { NEWID_PREFIX, WUNSCHTERMIN_LABEL } from '../../shared/constants';
import TextInputWithoutFormik from '../../shared/ui-components/TextInput/TextInputWithoutFormik';
import dayjs from 'dayjs';
import { createCustomStyles } from '../../shared/ui-components/SelectInput/CustomStyles';
import Label from '../../shared/ui-components/Label/Label';

type WarenempfaengerOption = ValueType<Option, false> & { warenempfaenger: Warenempfaenger };

const MAX_NUMBER_OF_VETRIEBSAUFTRAGSPOSITIONEN = 5;

const FormattedValueAfterSelection: FunctionComponent<SingleValueProps<OptionTypeBase>> = (props: SingleValueProps<OptionTypeBase>) => {
    const warenempfaenger = props.data.warenempfaenger as Warenempfaenger;
    const { hauptname, nebenname, namenszusatz, partnerNummer } = warenempfaenger;
    return <components.SingleValue {...props}>{`${hauptname} ${nebenname} ${namenszusatz} (${partnerNummer})`}</components.SingleValue>;
};

const PAGE_SIZE_KEY = 'Lieferungsuebersicht.pageSize';
const PAGE_INDEX_KEY = 'Lieferungsuebersicht.page';
const PAGE_SORTBY_KEY = 'Lieferungsuebersicht.sortBy';
const MOBILE_COLUMNS = ['vertriebsauftragsnummer', 'createdAt', 'warenempfaenger', 'wunschtermin', 'status'];
const createVertriebsauftragsnummerColumn = (isMobile: boolean): Column<Lieferung> => {
    return {
        id: 'vertriebsauftragsnummer',
        Header: 'Lieferung',
        accessor: function accessor(row: Lieferung): ReactElement | undefined {
            return isMobile ? (
                <h3 className={styles._mobileBestellnummer}>Lieferung {row.vertriebsauftragsnummer}</h3>
            ) : (
                <>
                    <div>{row.vertriebsauftragsnummer}</div>
                    {row.positionen.slice(0, MAX_NUMBER_OF_VETRIEBSAUFTRAGSPOSITIONEN).map((position, index: number) => {
                        return (
                            <Fragment key={index}>
                                <span className={styles._subInfo}>
                                    {position.artikelnummer.startsWith(NEWID_PREFIX) ? '' : `${position.artikelnummer} `}
                                    {position.bezeichnung} ({position.lieferungMenge} {position.lieferungEinheit})
                                </span>
                                <br />
                            </Fragment>
                        );
                    })}
                    {row.positionen.length > MAX_NUMBER_OF_VETRIEBSAUFTRAGSPOSITIONEN ? (
                        <span className={styles._subInfo}>...</span>
                    ) : (
                        <></>
                    )}
                </>
            );
        },
    };
};

const createFremdbelegnummerColumn = (): Column<Lieferung> => {
    return {
        id: 'fremdbelegnummer',
        Header: 'Bestellung',
        accessor: function accessor(row: Lieferung): ReactElement | undefined {
            return (
                <>
                    <div>{row.fremdbelegnummer}</div>
                </>
            );
        },
    };
};

const createLieferstatusColumn = (isDebitor: boolean, isMobile: boolean): Column<Lieferung> => {
    return {
        id: 'status',
        Header: isDebitor ? 'Status' : <>Liefer&shy;status</>,
        accessor: function accessor(row: Lieferung): ReactElement | undefined {
            const columnContent = (
                <>
                    {isDebitor ? (
                        <>
                            <Tippy content={fakturierungsstatusToText(row.fakturierungsstatus)} delay={200} disabled={isMobile}>
                                <img
                                    className={localStyles._statusIcon}
                                    src={fakturierungsstatusToIcon(row.fakturierungsstatus)}
                                    alt={fakturierungsstatusToText(row.fakturierungsstatus)}
                                />
                            </Tippy>
                            &nbsp;
                            {isMobile ? fakturierungsstatusToText(row.fakturierungsstatus) : null}
                        </>
                    ) : null}
                    &nbsp; &nbsp;
                    <>
                        <Tippy content={lieferstatusToText(row.lieferstatus)} delay={200} disabled={isMobile}>
                            <img
                                className={localStyles._statusIcon}
                                src={lieferstatusToIcon(row.lieferstatus)}
                                alt={lieferstatusToText(row.lieferstatus)}
                            />
                        </Tippy>
                        &nbsp;
                        {isMobile ? lieferstatusToText(row.lieferstatus) : null}
                    </>
                </>
            );

            return isMobile ? (
                <TextItem label="Lieferstatus" text={columnContent} compressed />
            ) : (
                <div className={localStyles._statusIcons}>{columnContent} </div>
            );
        },
        disableSortBy: false,
    };
};

const createWarenempfaengerColumn = (isMobile: boolean): Column<Lieferung> => {
    return {
        Header: <span>Warenempfänger</span>,
        accessor: 'warenempfaenger',
        Cell: function editCell({ cell: { value } }: EditCellProps): ReactElement {
            const warenempfaenger = value as unknown as Warenempfaenger;
            if (isMobile) {
                const warenempfaengerNamen = [warenempfaenger.hauptname, warenempfaenger.nebenname, warenempfaenger.namenszusatz].filter(
                    Boolean
                );

                const warenempfaengerText = (
                    <>
                        {warenempfaengerNamen.map((name) => {
                            return (
                                <>
                                    {name}
                                    <br />
                                </>
                            );
                        })}
                    </>
                );

                return <TextItem label="Warenempfänger" text={warenempfaengerText} compressed />;
            } else {
                return (
                    <div>
                        <div>{warenempfaenger.hauptname}</div>
                        {warenempfaenger.nebenname && <div>{warenempfaenger.nebenname}</div>}
                        {warenempfaenger.namenszusatz && <div>{warenempfaenger.namenszusatz}</div>}
                        <div className={styles._subInfo}>
                            {warenempfaenger.postleitzahl} {warenempfaenger.ort}
                        </div>
                    </div>
                );
            }
        },
        disableSortBy: false,
    };
};

const createRechnungsempfaengerColumn = (isDebitor: boolean): Column<Lieferung> => {
    return {
        Header: <span>{isDebitor ? 'Rechnungsempfänger' : 'Landwarenhandel'}</span>,
        accessor: 'rechnungsempfaenger',
        Cell: function editCell(editCellProps: EditCellProps): ReactElement {
            const cell = editCellProps.cell;
            const rechnungsempfaenger = cell.value as unknown as Rechnungsempfaenger;
            return (
                <div>
                    <div>{rechnungsempfaenger.hauptname}</div>
                    <div className={styles._subInfo}>
                        {rechnungsempfaenger.postleitzahl} {rechnungsempfaenger.ort}
                    </div>
                </div>
            );
        },
        disableSortBy: false,
    };
};

const createWunschterminColumn = (isMobile: boolean): Column<Lieferung> => {
    return {
        Header: 'Wunschtermin',
        id: 'wunschtermin',
        accessor: (row: Lieferung): Date | undefined => {
            return row.wunschtermin ? new Date(row.wunschtermin) : undefined;
        },
        Cell: function wunschtermin(cellProps: CellProps<BestellungDetails>): ReactElement {
            const cell = cellProps.cell;
            const wunschtermin = cell.value;
            if (!wunschtermin) return <></>;
            const { datum, uhrzeit } = convertDateWithTimeFromIsoStringToDateInfo(wunschtermin);
            return isMobile ? (
                <TextItem label="Wunschtermin" text={datum + ' - ' + uhrzeit} compressed />
            ) : (
                <>
                    <div>{datum}</div>
                    <span className={styles._subInfo}>{uhrzeit}</span>
                </>
            );
        },
    };
};

const createLieferungDetailsLinkColumn = (bestellnummer?: string): Column<Lieferung> => {
    return {
        Header: '',
        id: 'editColumn',
        accessor: 'vertriebsauftragsnummer',
        Cell: function editCell(editCellProps: EditCellProps): ReactElement {
            const cell = editCellProps.cell;
            const path = bestellnummer
                ? `${routes.bestellansicht}/${bestellnummer}/lieferungen/${cell.value}`
                : `${routes.lieferungsansicht}/${cell.value}`;
            return (
                <Link to={path}>
                    <img src={iconDetailsLink} className={styles._editIcon} alt="Lieferung ansehen" title="Lieferung ansehen" />
                </Link>
            );
        },
        disableSortBy: true,
    };
};

const getLieferungsuebersichtColumns = (isMobile: boolean, isDebitor: boolean, bestellnummer?: string): Column<Lieferung>[] => [
    createVertriebsauftragsnummerColumn(isMobile),
    createFremdbelegnummerColumn(),
    createLieferstatusColumn(isDebitor, isMobile),
    createWarenempfaengerColumn(isMobile),
    createRechnungsempfaengerColumn(isDebitor),
    createWunschterminColumn(isMobile),
    createLieferungDetailsLinkColumn(bestellnummer),
];

function loadLieferungenForPage(
    pageIndex: number,
    pageSize: number,
    sortBy: Array<SortingRule<Record<string, unknown>>>,
    dispatch: Dispatch,
    bestellnummer?: string,
    lieferStatus?: string,
    artikelNummer?: string,
    warenempfaengerNummer?: string,
    wunschtermin?: string
): void {
    let sortColumn = sortBy[0].id;
    if (sortColumn === 'warenempfaenger') {
        sortColumn = 'warenempfaengerName';
    }

    if (sortColumn === 'rechnungsempfaenger') {
        sortColumn = 'rechnungsempfaengerName';
    }
    if (sortColumn === 'status') {
        sortColumn = 'lieferStatus';
    }
    const filter: Filter = {};
    if (lieferStatus) {
        filter.lieferStatus = lieferStatus;
    }
    if (artikelNummer) {
        filter.artikelNummer = artikelNummer;
    }
    if (warenempfaengerNummer) {
        filter.warenempfaengerNummer = warenempfaengerNummer;
    }
    if (wunschtermin) {
        filter.wunschtermin = wunschtermin;
    }
    const sortOrder = sortBy[0].desc ? Order.DESC : Order.ASC;
    dispatch(getLieferungen(pageIndex + 1, pageSize, sortColumn, sortOrder, bestellnummer, filter));
}

const Lieferungsuebersicht = (): ReactElement => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { bestellnummer } = useParams<BestellungParam>();

    const lieferungen = useSelector((state: RootState) => state.lieferungen.lieferungen);
    const isLoading = useSelector((state: RootState) => state.lieferungen.isLoading);
    const loadFailed = useSelector((state: RootState) => state.lieferungen.loadFailed);
    const totalCount = useSelector((state: RootState) => state.lieferungen.totalCount);
    const isDebitor = useSelector(selectIsDebitor);
    const isFarmleiter = useSelector(selectIsFarmleiter);
    const isMobile = useMediaQuery({ query: `(max-width: ${parseInt(styles['breakpoint-tablet-portrait-up']) - 1 + 'px'})` });
    const warenempfaenger = useSelector((state: RootState) => state.warenempfaenger.warenempfaenger);
    const warenempfaengerLoading = useSelector((state: RootState) => state.warenempfaenger.isLoading);
    const [warenempfaengerFilter, setWarenempfaengerFilter] = useState<WarenempfaengerOption>();
    const [lieferstatusFilterOption, setLieferstatusFilterOption] = useState<OptionTypeBase>();
    const [artikelnummerFilter, setArtikelnummerFilter] = useState('');
    const [artikelnummerFilterInternalValue, setArtikelnummerFilterInternalValue] = useState('');
    const [wunschterminFilter, setWunschterminFilter] = useState('');
    const [wunschterminFilterInternalValue, setWunschterminInternalValue] = useState('');

    const [pageSizeState, setPageSizeState] = useState<number>(parseInt(localStorageWrapper.getItem(PAGE_SIZE_KEY) || '10'));

    const lieferstatusOptions = [
        { value: 'KL', label: 'komplett geliefert' },
        { value: 'TL', label: 'teilweise geliefert' },
        { value: 'NL', label: 'nicht geliefert' },
    ];

    useEffect(() => {
        localStorageWrapper.setItem(PAGE_SIZE_KEY, pageSizeState.toString());
    }, [pageSizeState]);

    useEffect(() => {
        dispatch(getWarenempfaenger());
    }, [dispatch]);

    const warenempfaengerOptionen = warenempfaenger.map((we): Option & { warenempfaenger: Warenempfaenger } => ({
        value: `${we.partnerNummer} ${we.hauptname} ${we.nebenname} ${we.namenszusatz}`,
        label: (
            <>
                <div>{we.hauptname}</div>
                <div>{we.nebenname}</div>
                <div>
                    <span>{we.namenszusatz}</span>
                    <span> ({we.partnerNummer?.startsWith(NEWID_PREFIX) ? 'Neu angelegt' : we.partnerNummer})</span>
                </div>
            </>
        ),
        warenempfaenger: we,
    }));

    useDocumentTitle('Meine Lieferungen');

    const columns: Column<Lieferung>[] = useMemo(
        () => getLieferungsuebersichtColumns(isMobile, isDebitor || isFarmleiter, bestellnummer),
        [bestellnummer, isDebitor, isFarmleiter, isMobile]
    );

    const initialPageCount = Math.ceil(totalCount / pageSizeState);
    const tableInstance = useTable<Lieferung>(
        {
            columns,
            data: lieferungen,
            initialState: {
                sortBy: [
                    {
                        id: JSON.parse(localStorageWrapper.getItem(PAGE_SORTBY_KEY) || '[]')[0]?.id || 'vertriebsauftragsnummer',
                        desc: JSON.parse(localStorageWrapper.getItem(PAGE_SORTBY_KEY) || '[]')[0]?.desc || true,
                    },
                ],
                pageSize: pageSizeState,
                pageIndex: bestellnummer ? 0 : parseInt(localStorageWrapper.getItem(PAGE_INDEX_KEY) || '0'),
            },
            pageCount: initialPageCount,
            manualPagination: true,
            manualSortBy: true,
            disableMultiSort: true,
            disableSortRemove: true,
        },
        useSortBy,
        usePagination
    );

    const { state } = tableInstance;

    useEffect(() => {
        loadLieferungenForPage(
            state.pageIndex,
            state.pageSize,
            state.sortBy,
            dispatch,
            bestellnummer,
            lieferstatusFilterOption?.value,
            artikelnummerFilter,
            warenempfaengerFilter?.warenempfaenger.partnerNummer,
            wunschterminFilter
        );
    }, [
        dispatch,
        state.pageIndex,
        state.pageSize,
        state.sortBy,
        bestellnummer,
        lieferstatusFilterOption,
        artikelnummerFilter,
        warenempfaengerFilter,
        wunschterminFilter,
    ]);

    useEffect(() => {
        if (!bestellnummer) {
            localStorageWrapper.setItem(PAGE_INDEX_KEY, state.pageIndex.toString());
        }
        localStorageWrapper.setItem(PAGE_SORTBY_KEY, JSON.stringify(state.sortBy));
    }, [state.pageIndex, state.sortBy, bestellnummer]);

    const onArtikelnummerFilterKeyDown = (event: KeyboardEvent): void => {
        if (event.key === 'Enter' || event.code === 'Enter') {
            setArtikelnummerFilter(artikelnummerFilterInternalValue);
        }
    };

    const onWunschterminFilterChanged = (value: string): void => {
        const selectedDate = dayjs(value);
        if (selectedDate.isBefore('2100-01-01', 'day') && selectedDate.isAfter('2000-01-01', 'day')) {
            setWunschterminInternalValue(value);
            setWunschterminFilter(value);
        } else {
            if (!value) {
                setWunschterminFilter(value);
            }
            setWunschterminInternalValue(value);
        }
    };

    return (
        <>
            <h2>
                {bestellnummer ? (
                    <>
                        <span>Lieferungen zu </span>
                        <Link to={`${routes.bestellansicht}/${bestellnummer}`}>{bestellnummer}</Link>
                    </>
                ) : (
                    'Meine Lieferungen'
                )}
            </h2>
            {bestellnummer && (
                <p>
                    <Link to={routes.lieferungsuebersicht}>Alle Lieferungen anzeigen.</Link>
                </p>
            )}
            <NotificationBar
                testId="loadError-message-bar"
                message="Die Lieferungen konnten nicht geladen werden. Bitte versuchen Sie es noch einmal."
                isVisible={loadFailed}
                actionText="Neu laden"
                actionCallback={(): void => {
                    loadLieferungenForPage(state.pageIndex, state.pageSize, state.sortBy, dispatch);
                }}
            />
            <LoadingSpinner isLoading={isLoading}>
                <>
                    {bestellnummer && lieferungen.length === 0 ? (
                        <p>Zu dieser Bestellung existieren noch keine Lieferungen</p>
                    ) : (
                        <>
                            <div className={styles._panelAboveTable}>
                                <div className={styles._filterPanel}>
                                    <div className={styles._filter}>
                                        <Label>{WUNSCHTERMIN_LABEL}</Label>
                                        <TextInputWithoutFormik
                                            type="date"
                                            name="wunschterminFilter"
                                            id="wunschterminFilter"
                                            placeholder="Wunschtermin Filter"
                                            value={wunschterminFilterInternalValue}
                                            onFieldChange={onWunschterminFilterChanged}
                                            onFieldBlur={setWunschterminFilter}
                                        />
                                    </div>
                                    <div className={styles._filter}>
                                        <TextInputWithoutFormik
                                            name="artikelnummerFilter"
                                            id="artikelnummerFilter"
                                            placeholder="Artikelnummer Filter"
                                            value={artikelnummerFilterInternalValue}
                                            onKeyDown={onArtikelnummerFilterKeyDown}
                                            onFieldChange={setArtikelnummerFilterInternalValue}
                                            onFieldBlur={setArtikelnummerFilter}
                                        />
                                    </div>
                                    <div className={styles._filter}>
                                        <Select
                                            options={lieferstatusOptions}
                                            name="lieferstatusFilter"
                                            value={lieferstatusFilterOption}
                                            onChange={(selectedOption): void => {
                                                setLieferstatusFilterOption(selectedOption);
                                            }}
                                            styles={createCustomStyles(false, false, 'left')}
                                            placeholder="Lieferstatus Filter"
                                            menuShouldScrollIntoView={true}
                                            menuPlacement={'auto'}
                                            isClearable={true}
                                        />
                                    </div>
                                    {(isDebitor || isFarmleiter) && (
                                        <div className={styles._filter}>
                                            <Select
                                                options={warenempfaengerOptionen}
                                                name={'warenempfaengerFilter'}
                                                value={warenempfaengerFilter}
                                                onChange={(selectedOption): void => {
                                                    setWarenempfaengerFilter(selectedOption as unknown as WarenempfaengerOption);
                                                }}
                                                styles={createCustomStyles(false, false, 'left')}
                                                placeholder="Warenempfänger Filter"
                                                menuShouldScrollIntoView={true}
                                                components={{ SingleValue: FormattedValueAfterSelection }}
                                                menuPlacement={'auto'}
                                                noOptionsMessage={(): string => 'Keine Einträge'}
                                                isLoading={warenempfaengerLoading}
                                                isClearable={true}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            {isMobile ? (
                                <OverviewList
                                    tableInstance={tableInstance}
                                    onRowClick={(row): void => {
                                        const path = bestellnummer
                                            ? `${routes.bestellansicht}/${bestellnummer}/lieferungen/${row.original['vertriebsauftragsnummer']}`
                                            : `${routes.lieferungsansicht}/${row.original['vertriebsauftragsnummer']}`;
                                        history.push(path);
                                    }}
                                    mobileColumns={MOBILE_COLUMNS}
                                />
                            ) : (
                                <OverviewTable tableInstance={tableInstance} />
                            )}
                        </>
                    )}
                    {lieferungen.length > 0 && (
                        <OverviewPagination tableInstance={tableInstance} setPageSizeState={setPageSizeState} totalCount={totalCount} />
                    )}
                </>
            </LoadingSpinner>
        </>
    );
};

export default Lieferungsuebersicht;
