import { Partner } from '../shared/types';
import type { RootState } from '../configureStore';

export const GET_PARTNER = 'GET_PARTNER';
export const GET_PARTNER_SUCCEEDED = 'GET_PARTNER_SUCCEEDED';
export const GET_PARTNER_FAILED = 'GET_PARTNER_FAILED';
export const GET_DEBITOR_PARTNER = 'GET_DEBITOR_PARTNER';
export const GET_PARTNER_FROM_DEBITOR = 'GET_PARTNER_FROM_DEBITOR';
export const GET_PARTNER_FROM_DEBITOR_SUCCESS = 'GET_PARTNER_FROM_DEBITOR_SUCCESS';
export const GET_PARTNER_FROM_DEBITOR_AFTER_DELETE = 'GET_PARTNER_FROM_DEBITOR_AFTER_DELETE';
export const RESET_WE_SEARCH = 'RESET_WE_SEARCH_ACTION';
export const RESET_PARTNERS = 'RESET_ALL_PARTNERS_ACTION';

export interface GetPartnerAfterDeleteAction {
    type: typeof GET_PARTNER_FROM_DEBITOR_AFTER_DELETE;
    partner: Partner[];
}

export interface ResetPartnerSearch {
    type: typeof RESET_PARTNERS;
}

export interface GetPartnerAction {
    type: typeof GET_PARTNER;
    searchQuery: string;
}

export interface GetPartnerSucceededAction {
    type: typeof GET_PARTNER_SUCCEEDED;
    partner: Partner[];
}

export interface GetPartnerFailedAction {
    type: typeof GET_PARTNER_FAILED;
}

export interface GetDebitorPartnerAction {
    type: typeof GET_DEBITOR_PARTNER;
    searchQuery: string;
}

export function getDebitor(searchQuery: string): GetDebitorPartnerAction {
    return {
        type: GET_DEBITOR_PARTNER,
        searchQuery,
    };
}

export interface GetPartnerFromDebitor {
    debitorNummern: string[];
    query: string;
    type: typeof GET_PARTNER_FROM_DEBITOR;
}

export interface GetPartnerFromDebitorSuccess {
    warenEmpfaenger: Partner[];
    type: typeof GET_PARTNER_FROM_DEBITOR_SUCCESS;
}

export function GetPartnerFromDebitor(debitoren: Partner[], query: string): GetPartnerFromDebitor {
    return {
        debitorNummern: debitoren.map((d) => d.partnerNummer),
        query,
        type: GET_PARTNER_FROM_DEBITOR,
    };
}

export function getPartner(searchQuery: string): GetPartnerAction {
    return {
        type: GET_PARTNER,
        searchQuery,
    };
}

export function getPartnerSucceeded(partner: Partner[]): GetPartnerSucceededAction {
    return {
        type: GET_PARTNER_SUCCEEDED,
        partner,
    };
}

export function getPartnerFailed(): GetPartnerFailedAction {
    return {
        type: GET_PARTNER_FAILED,
    };
}

export interface ResetWESearch {
    type: typeof RESET_WE_SEARCH;
}

export type PartnerSucheActions =
    | GetPartnerAction
    | GetPartnerSucceededAction
    | GetPartnerFailedAction
    | GetDebitorPartnerAction
    | GetPartnerFromDebitor
    | GetPartnerFromDebitorSuccess
    | ResetWESearch
    | ResetPartnerSearch;

export type PartnerSucheState = {
    partner: Partner[];
    warenEmpfaengerPartner: Partner[];
    loadFailed: boolean;
    isLoading: boolean;
    loadFinished: boolean;
};

export const INITIAL_PARTNER_SUCHE_STATE: PartnerSucheState = {
    partner: [],
    warenEmpfaengerPartner: [],
    loadFailed: false,
    isLoading: false,
    loadFinished: false,
};

const selectPartnerSuche = (state: RootState): PartnerSucheState => state.partnersuche;
const selectPartner = (state: RootState): Partner[] => selectPartnerSuche(state).partner;
export const selectWarenEmpfaenger = (state: RootState): Partner[] => state.partnersuche.warenEmpfaengerPartner;

export const PartnerSucheSelectors = {
    partner: selectPartner,
    warenEmpfaenger: (state: RootState): Partner[] => selectWarenEmpfaenger(state),
    isLoading: (state: RootState): boolean => selectPartnerSuche(state).isLoading,
    loadFailed: (state: RootState): boolean => selectPartnerSuche(state).loadFailed,
    loadFinished: (state: RootState): boolean => selectPartnerSuche(state).loadFinished,
};

export function partnerSucheReducer(state = INITIAL_PARTNER_SUCHE_STATE, action: PartnerSucheActions): PartnerSucheState {
    console.log(action.type);
    switch (action.type) {
        case GET_PARTNER_FROM_DEBITOR_SUCCESS:
            console.log(action.warenEmpfaenger);
            return {
                ...state,
                warenEmpfaengerPartner: [...action.warenEmpfaenger],
                isLoading: false,
                loadFailed: false,
                loadFinished: true,
            };
        case GET_DEBITOR_PARTNER:
        case GET_PARTNER_FROM_DEBITOR:
        case GET_PARTNER:
            return {
                ...state,
                isLoading: true,
                loadFailed: false,
                loadFinished: false,
            };
        case GET_PARTNER_FAILED:
            return {
                ...state,
                loadFailed: true,
                isLoading: false,
                loadFinished: true,
            };
        case GET_PARTNER_SUCCEEDED:
            return {
                ...state,
                partner: action.partner,
                loadFailed: false,
                isLoading: false,
                loadFinished: true,
            };
        case RESET_WE_SEARCH:
            return { ...state, warenEmpfaengerPartner: [] };
        case RESET_PARTNERS:
            return { ...state, warenEmpfaengerPartner: [], partner: [] };
        default:
            return state;
    }
}
