// Generated code; DO NOT EDIT

const buildinfo = {
    version: '0.1.0',
    gitcommit: '11822e5',
    buildDate: '2025-02-21T17:36:56.456Z',
};

console.log('version: 0.1.0 git: 11822e5 date: 2025-02-21T17:36:56.456Z');

export default buildinfo;
