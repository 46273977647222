import { CurrentUser } from '../shared/types';
import { RootState } from '../configureStore';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';

export const selectCurrentUser = (state: RootState): CurrentUser | undefined => {
    return state.keycloakState.user;
};

export interface KeycloakState {
    user?: CurrentUser;
}

export const defaultState = {
    user: undefined,
};

export type KeycloakStateActions = SetCurrentUser;

export interface SetCurrentUser {
    type: typeof SET_CURRENT_USER;
    user: CurrentUser;
}

export function keycloakReducer(state: KeycloakState = defaultState, action: KeycloakStateActions): KeycloakState {
    switch (action.type) {
        case SET_CURRENT_USER:
            return { user: action.user };
        default:
            return state;
    }
}
