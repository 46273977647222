import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ArtikelSackwarenSelectors } from '../../store/ArtikelSackware.store';
import { Form, Formik } from 'formik';
import { BaseBestellposition } from '../../shared/types';
import { useHistory } from 'react-router-dom';
import {
    BestellungSelectors,
    mergeVorschlaegeWithBestellpositionen,
    selectDateAndTimeFromWunschtermin,
    selectDateAndTimeFromWunschterminBis,
    selectDateAndTimeFromWunschterminVon,
    setBestellpositionen,
    setWunschtermin,
    setWunschterminVonBis,
} from '../../store/Bestellung.store';
import * as BestellungenHelper from '../../shared/helper/bestellungen-helper';
import { selectArtikelLoseWareList, selectArtikelLoseWareLoaded } from '../../store/ArtikelLoseWare.store';
import { selectNextRoute, setCurrentWorkflowStep, WorkflowStep } from '../../store/Workflow.store';
import { ArtikelNutztierfutterFormular } from '../../shared/helper/artikel-helper';
import { getISOWunschtermin } from '../../shared/helper/date-helper';
import { Bestellpositionen } from '../../shared/content-components/Bestellpositionen/Bestellpositionen';
import { getVorschlaege } from '../../store/Vorschlaege.store';
import type { RootState } from '../../configureStore';
import MandatoryFormFieldHint from '../../shared/ui-components/Form/MandatoryFormFieldHint';
import useDocumentTitle from '../../shared/hooks/useDocumentTitle';
import { Bestellprozesstyp } from '../../shared/types/enums';
import { selectIsDebitor, selectIsFarmleiter, selectIsWarenempfaenger } from '../../store/Navigation.store';
import { Wunschtermin } from '../../shared/content-components/Wunschtermin/Wunschtermin';
import { BestellpositionenNotifications } from '../../shared/content-components/BestellpositionenNotifications/BestellpositionenNotifications';
import { FormRow } from '../../shared/ui-components/Form/FormRow';
import { FormColumn } from '../../shared/ui-components/Form/FormColumn';
import { useArtikelNutztierFormularSchema } from './useArtikelNutztierFormularSchema';
import { RechnungsempfaengerErrorNotification } from '../../shared/content-components/Notifications/RechnungsempfaengerErrorNotification';
import { WarenempfaengerErrorNotification } from '../../shared/content-components/Notifications/WarenempfaengerErrorNotification';
import { ArtikelNutztierWorkflowButtons } from './ArtikelNutztierWorkflowButtons';
import { WunschterminMode } from '../../shared/content-components/Wunschtermin/wunschtermin-helper';
import { Artikelauswahl } from '../../shared/content-components/Artikelauswahl/Artikelauswahl';
import { useFetchArtikelLoseWare } from '../../shared/hooks/useFetchArtikelLoseWare';
import { useFetchWerkeForArtikel } from '../../shared/hooks/useFetchWerke';
import { removeFromListOfSelectedArtikel, setArtikelNummern } from '../../store/Artikelauswahl.store';
import { useFetchArtikelSackwaren } from '../../shared/hooks/useFetchArtikelSackwaren';

const ArtikelNutztierfutter = (): JSX.Element => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isDebitor = useSelector(selectIsDebitor);
    const isFarmleiter = useSelector(selectIsFarmleiter);
    const isWarenempfaenger = useSelector(selectIsWarenempfaenger);
    const nextRoute = useSelector(selectNextRoute);

    const sackwareLoaded = useSelector(ArtikelSackwarenSelectors.loaded);
    const artikelSackwareList = useSelector(ArtikelSackwarenSelectors.list);

    const loseWareLoaded = useSelector(selectArtikelLoseWareLoaded);
    const artikelLoseWareList = useSelector(selectArtikelLoseWareList);

    const wunschterminVon = useSelector(selectDateAndTimeFromWunschterminVon);
    const wunschterminBis = useSelector(selectDateAndTimeFromWunschterminBis);
    const wunschtermin = useSelector(selectDateAndTimeFromWunschtermin);

    const rechnungsempfaengerPartnerNummer = useSelector(BestellungSelectors.rechnungsempfaengerPartnerNummer);
    const warenempfaengerPartnerNummer = useSelector(BestellungSelectors.warenempfaengerPartnerNummer);

    const artikelNutztierfutterValidationSchema = useArtikelNutztierFormularSchema();

    const bestellpositionenView = useSelector(BestellungSelectors.bestellpositionenViewFromBestellpositionenAndArtikeln);
    const bestellpositionenViewNummern = useSelector(BestellungSelectors.bestellpositionenViewNummern);

    const { vorschlaege, vorschlaegeLoaded } = useSelector((state: RootState) => ({
        vorschlaegeLoaded: state.vorschlaege.loadFinished,
        vorschlaege: state.vorschlaege.vorschlaege,
    }));

    useDocumentTitle('Artikel lose Ware (Sackware kombinierbar)');

    useEffect(() => {
        dispatch(setCurrentWorkflowStep(WorkflowStep.ErfassungNutztierfutter, history));
    }, [dispatch, history]);

    const reloadArtikelLoseWare = useFetchArtikelLoseWare(
        rechnungsempfaengerPartnerNummer,
        warenempfaengerPartnerNummer,
        Bestellprozesstyp.Nutztierfutter
    );
    const reloadArtikelSackware = useFetchArtikelSackwaren(
        rechnungsempfaengerPartnerNummer,
        warenempfaengerPartnerNummer,
        Bestellprozesstyp.Nutztierfutter
    );
    const reloadWerke = useFetchWerkeForArtikel(bestellpositionenViewNummern, rechnungsempfaengerPartnerNummer);

    useEffect(() => {
        if (!vorschlaegeLoaded && rechnungsempfaengerPartnerNummer && warenempfaengerPartnerNummer) {
            dispatch(getVorschlaege(rechnungsempfaengerPartnerNummer, warenempfaengerPartnerNummer));
        }
    }, [dispatch, warenempfaengerPartnerNummer, vorschlaegeLoaded, rechnungsempfaengerPartnerNummer]);

    useEffect(() => {
        if (loseWareLoaded && sackwareLoaded && vorschlaegeLoaded) {
            dispatch(
                mergeVorschlaegeWithBestellpositionen({
                    artikelLoseWare: artikelLoseWareList,
                    artikelSackware: artikelSackwareList,
                    vorschlaege,
                })
            );
        }
    }, [dispatch, artikelLoseWareList, artikelSackwareList, vorschlaege, loseWareLoaded, sackwareLoaded, vorschlaegeLoaded]);

    useEffect(() => {
        dispatch(setArtikelNummern(bestellpositionenViewNummern));
    }, [bestellpositionenViewNummern, dispatch]);

    const initialValues: ArtikelNutztierfutterFormular = isWarenempfaenger
        ? {
              datumWEVon: wunschterminVon?.datum ?? '',
              datumWEBis: wunschterminBis?.datum ?? '',
              uhrzeitWEVon: wunschterminVon?.uhrzeit ?? '',
              uhrzeitWEBis: wunschterminBis?.uhrzeit ?? '',
              datumDebitor: '',
              bestellpositionenView: bestellpositionenView,
              addArticle: undefined,
          }
        : {
              datumWEVon: '',
              datumDebitor: wunschtermin?.datum ?? '',
              uhrzeitDebitor: wunschtermin?.uhrzeit ?? '',
              bestellpositionenView: bestellpositionenView,
              addArticle: undefined,
          };
    const [triedToSubmit, setTriedToSubmit] = useState(false);

    const onArtikelDeleted = useCallback(
        (artikelNummer: string): void => {
            dispatch(removeFromListOfSelectedArtikel(artikelNummer));
        },
        [dispatch]
    );

    const onSubmit = useCallback(
        (values: ArtikelNutztierfutterFormular, { setErrors }): void => {
            // Duplikatüberprüfung
            const duplicatePosition = values.bestellpositionenView.find((position, index) => {
                return values.bestellpositionenView.some((otherPosition, otherIndex) => {
                    const sameArticle = position.nummer === otherPosition.nummer && index !== otherIndex;
                    const sameSilo = 'silo' in position && 'silo' in otherPosition && position.silo === otherPosition.silo;
                    const sameMenge =
                        ('mengeVe' in position && 'mengeVe' in otherPosition && position.mengeVe === otherPosition.mengeVe) ||
                        ('menge' in position && 'menge' in otherPosition && position.menge === otherPosition.menge);

                    return sameArticle && sameSilo && sameMenge;
                });
            });

            if (duplicatePosition) {
                const artikelName = duplicatePosition.bezeichnung || duplicatePosition.nummer;
                const silo = duplicatePosition.silo;
                const menge = 'mengeVe' in duplicatePosition ? duplicatePosition.mengeVe : duplicatePosition.menge;
                const errorMessage = `Der Artikel "${artikelName}" mit dem Silo "${silo}" und der Menge "${menge}" wurde bereits hinzugefügt.`;

                setErrors({
                    bestellpositionenView: errorMessage,
                });
                return;
            }

            const neueBestellpositionen: BaseBestellposition[] = values.bestellpositionenView
                .filter(BestellungenHelper.onlyBestellpositionenWithMenge)
                .map(BestellungenHelper.mapBestellpositionenViewToBestellpositionen);

            if (isDebitor || isFarmleiter) {
                const wunschtermin = getISOWunschtermin(values.datumDebitor, values.uhrzeitDebitor);
                if (wunschtermin) {
                    dispatch(setWunschtermin(wunschtermin));
                }
            } else if (isWarenempfaenger) {
                const wunschterminVon = getISOWunschtermin(values.datumWEVon, values.uhrzeitWEVon);
                const wunschterminBis = getISOWunschtermin(values.datumWEBis, values.uhrzeitWEBis);
                if (wunschterminVon) {
                    dispatch(setWunschterminVonBis({ wunschterminVon, wunschterminBis }));
                }
            }
            dispatch(setBestellpositionen(neueBestellpositionen));
            history.push(nextRoute);
        },
        [dispatch, history, isDebitor, isFarmleiter, isWarenempfaenger, nextRoute]
    );

    if (!rechnungsempfaengerPartnerNummer) {
        return <RechnungsempfaengerErrorNotification />;
    } else if (!warenempfaengerPartnerNummer) {
        return <WarenempfaengerErrorNotification />;
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={artikelNutztierfutterValidationSchema}
            onSubmit={onSubmit}
            enableReinitialize
            validateOnChange
        >
            <Form>
                <h2>Futter&shy;bestellung</h2>
                <h3>Wunschtermin</h3>
                <FormRow>
                    <FormColumn>
                        <Wunschtermin mode={WunschterminMode.UHRZEIT} />
                    </FormColumn>
                </FormRow>
                <br />
                <h3>Artikelauswahl</h3>
                <Artikelauswahl artikelSackwareList={artikelSackwareList} artikelLoseWareList={artikelLoseWareList} />
                <h3>Bestellung aufgeben</h3>
                <BestellpositionenNotifications
                    reloadArtikelCallback={() => {
                        reloadArtikelLoseWare();
                        reloadArtikelSackware();
                        reloadWerke();
                    }}
                    triedToSubmit={triedToSubmit}
                />
                <p></p>
                <Bestellpositionen onArtikelDeleted={onArtikelDeleted} />
                <MandatoryFormFieldHint />
                <ArtikelNutztierWorkflowButtons setTriedToSubmit={setTriedToSubmit} />
            </Form>
        </Formik>
    );
};

export default ArtikelNutztierfutter;
