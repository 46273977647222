import { useDispatch, useSelector } from 'react-redux';
import { selectIsDebitor, selectIsFarmleiter } from '../../store/Navigation.store';
import { getSpeditionen, speditionSelectors } from '../../store/Spedition.store';
import { useEffect } from 'react';

export const useFetchSpedition = (): void => {
    const dispatch = useDispatch();
    const isDebitor = useSelector(selectIsDebitor);
    const isFarmleiter = useSelector(selectIsFarmleiter);
    const loaded = useSelector(speditionSelectors.loaded);

    useEffect(() => {
        if ((isDebitor || isFarmleiter) && !loaded) {
            dispatch(getSpeditionen());
        }
    }, [isDebitor, isFarmleiter, dispatch, loaded]);
};
