import { selectIsHeimtierfutter } from '../../store/Workflow.store';
import { useMemo } from 'react';
import { minDate } from '../../shared/helper/date-helper';
import { selectIsDebitor, selectIsFarmleiter } from '../../store/Navigation.store';
import { useSelector } from 'react-redux';
import { createDateSchema, createTimeSchema } from '../../shared/validation/bestellung/validation';
import { date, DateSchema, object, string, StringSchema } from 'yup';
import { datumWEBisSchema, uhrzeitWEBisSchema } from '../../shared/validation/bestellung/schemas';

export type BestelldatenDebitorValidationSchema = {
    lieferbedingung: StringSchema;
    datumDebitor: DateSchema;
    uhrzeitDebitor: StringSchema;
};

export type BestelldatenWarenempfaengerValidationSchema = {
    datumWEVon: DateSchema;
    uhrzeitWEVon: StringSchema;
};

// NOTE: Ignore because we could not get yup typings working correctly
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useBestelldatenSchema = () => {
    const isDebitor = useSelector(selectIsDebitor);
    const isFarmleiter = useSelector(selectIsFarmleiter);
    const isHeimtierfutter = useSelector(selectIsHeimtierfutter);

    return useMemo(() => {
        const DebitorSchema = object({
            lieferbedingung: string().required(),
            datumDebitor: date().when([], {
                is: () => isHeimtierfutter,
                then: createDateSchema(minDate),
            }),
            uhrzeitDebitor: string().when([], {
                is: () => isHeimtierfutter,
                then: createTimeSchema(),
            }),
        });

        const WarenempfaengerSchema = object({
            datumWEVon: date().when([], {
                is: () => isHeimtierfutter,
                then: createDateSchema(minDate),
            }),
            datumWEBis: datumWEBisSchema,
            uhrzeitWEVon: string().when([], {
                is: () => isHeimtierfutter,
                then: createTimeSchema(false),
            }),
            uhrzeitWEBis: uhrzeitWEBisSchema,
        });

        return isDebitor || isFarmleiter ? DebitorSchema : WarenempfaengerSchema;
    }, [isDebitor, isFarmleiter, isHeimtierfutter]);
};
