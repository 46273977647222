import { ForkEffect, takeLatest } from '@redux-saga/core/effects';
import { GET_WARENEMPFAENGER } from '../store/Warenempfaenger.store';
import { getWarenempfaengerSaga } from './warenempfaenger.sagas';
import { GET_WERKE, GET_WERKE_FOR_ARTIKEL } from '../store/Abholwerk.store';
import { getWerkeForArtikelSaga, getWerkeSaga } from './werke.sagas';
import { getArtikelSackwareSaga } from './artikelSackware.sagas';
import { sendBestellungSaga, updateBestellungBatchSaga, updateBestellungSaga, updateLieferbedingungSaga } from './bestellung.sagas';
import { addKfzKennzeichen, deleteKfzKennzeichen, getKfzKennzeichen, updateKfzKennzeichen } from '../store/KfzKennzeichen.store';
import { deleteKfzKennzeichenSaga, getKfzKennzeichenSaga, putKfzKennzeichen, updateKfzKennzeichenSaga } from './kfzKennzeichen.saga';
import { takeEvery } from 'redux-saga/effects';
import { GET_ARTIKEL_LOSE_WARE } from '../store/ArtikelLoseWare.store';
import { getArtikelLoseWareSaga } from './artikelLoseWare.sagas';
import { SET_CURRENT_WORKFLOW_STEP } from '../store/Workflow.store';
import { workflowSaga } from './workflow.saga';
import { DELETE_USER, GET_USER, GET_USER_DETAILS, UPDATE_USER } from '../store/User.store';
import { deleteUserSaga, getUserDetailsSaga, getUserSaga, updateUserSaga } from './user.sagas';
import { GET_DEBITOR_PARTNER, GET_PARTNER, GET_PARTNER_FROM_DEBITOR, GET_PARTNER_FROM_DEBITOR_AFTER_DELETE } from '../store/Partner.store';
import { getDebitorPartnerSaga, getPartnerSaga, getWarenEmpfaengerFromDebitor, updateSelectedWarenEmpfaenger } from './partner.saga';
import { GET_RECHNUNGSEMPFAENGER } from '../store/Rechnungsempfaenger.store';
import { getRechnungsempfaengerSaga } from './rechnungsempfaenger.sagas';
import { getBestellung, getBestellungen } from '../store/Bestelluebersicht.store';
import { getBestellungenSaga, getBestellungSaga } from './bestelluebersicht.sagas';
import { GET_LIEFERUNG, GET_LIEFERUNGEN } from '../store/Lieferung.store';
import { getLieferungenSaga, getLieferungSaga } from './lieferungsuebersicht.sagas';
import { getKontrakteForArtikelSaga } from './kontrakte.sagas';
import { GET_PARTNER_DETAILS, GET_PARTNER_LIST, UPDATE_PARTNER } from '../store/Partneruebersicht.store';
import { getPartnerDetailsSaga, getPartnerListSaga, updatePartnerSaga } from './partneruebersicht.sagas';
import { GET_MARKEN } from '../store/Marke.store';
import { getMarkenSaga } from './marken.saga';
import { SEARCH_INTERNE_ARTIKEL } from '../store/InterneArtikelSuche.store';
import { searchInterneArtikelSaga } from './interneArtikelSuche.sagas';
import { getPreislistenkennzeichenSaga, postPreislistenkennzeichenSaga } from './preislistenkennzeichen.saga';
import { GET_PREISLISTENKENNZEICHEN, SET_PREISLISTENKENNZEICHEN } from '../store/Preislistenkennzeichen.store';
import { getSollbestandSaga, postSollbestandSaga } from './sollbestand.sagas';
import { GET_SOLLBESTAND, POST_SOLLBESTAND } from '../store/Sollbestand.store';
import { getVorschlaegeSaga } from './vorschlaege.saga';
import { GET_VORSCHLAEGE } from '../store/Vorschlaege.store';
import { getKontrakteForArtikel } from '../store/Kontrakt.store';
import { getKontrakt, getKontrakte } from '../store/Kontraktuebersicht.store';
import { getKontrakteSaga, getKontraktSaga } from './kontraktuebersicht.sagas';
import { sendBestellung, updateBestellung, updateBestellungBatch, updateLieferbedingung } from '../store/Bestellung.store';
import { deleteDokument, dokumentDownload, dokumenteDownload, getDokumente } from '../store/Dokument.store';
import { getDokumenteSaga } from './dokumentenuebersicht.sagas';
import { deleteDokumenteSaga, downloadDokumentSaga, downloadMultipleDokumenteSaga } from './dokument.sagas';
import { getRationsservicesSaga } from './rationsservice.sagas';
import { getRationsservices } from '../store/Rationsservice.store';
import { getArtikelSackware } from '../store/ArtikelSackware.store';
import { getDokumenteSilageergebnisse } from '../store/DokumentSilageergebnisse.store';
import { getDokumenteSilageergebnisseSaga } from './dokumentSilageergebnisse.sagas';
import { addSpedition, deleteSpedition, getSpeditionen, updateSpedition } from '../store/Spedition.store';
import { deleteSpeditionSaga, getSpeditionenSaga, putSpedition, updateSpeditionSaga } from './spedition.saga';

function* rootSaga(): IterableIterator<ForkEffect> {
    yield takeLatest(GET_WARENEMPFAENGER, getWarenempfaengerSaga);
    yield takeLatest(GET_WERKE, getWerkeSaga);
    yield takeLatest(getArtikelSackware.type, getArtikelSackwareSaga);
    yield takeLatest(GET_ARTIKEL_LOSE_WARE, getArtikelLoseWareSaga);
    yield takeLatest(sendBestellung.type, sendBestellungSaga);
    yield takeLatest(updateLieferbedingung.type, updateLieferbedingungSaga);
    yield takeLatest(updateBestellung.type, updateBestellungSaga);
    yield takeLatest(updateBestellungBatch.type, updateBestellungBatchSaga);
    yield takeLatest(addKfzKennzeichen.type, putKfzKennzeichen);
    yield takeLatest(getKfzKennzeichen.type, getKfzKennzeichenSaga);
    yield takeLatest(addSpedition.type, putSpedition);
    yield takeLatest(getSpeditionen.type, getSpeditionenSaga);
    yield takeLatest(GET_RECHNUNGSEMPFAENGER, getRechnungsempfaengerSaga);
    yield takeLatest(getBestellungen.type, getBestellungenSaga);
    yield takeLatest(getKontrakte.type, getKontrakteSaga);
    yield takeLatest(getKontrakt.type, getKontraktSaga);
    yield takeLatest(getBestellung.type, getBestellungSaga);
    yield takeLatest(GET_LIEFERUNGEN, getLieferungenSaga);
    yield takeLatest(GET_LIEFERUNG, getLieferungSaga);
    yield takeLatest(GET_MARKEN, getMarkenSaga);
    yield takeLatest(POST_SOLLBESTAND, postSollbestandSaga);
    yield takeLatest(GET_SOLLBESTAND, getSollbestandSaga);
    yield takeLatest(GET_VORSCHLAEGE, getVorschlaegeSaga);
    yield takeLatest(getDokumente.type, getDokumenteSaga);
    yield takeLatest(dokumentDownload.type, downloadDokumentSaga);
    yield takeLatest(dokumenteDownload.type, downloadMultipleDokumenteSaga);
    yield takeEvery(deleteDokument.type, deleteDokumenteSaga);
    yield takeLatest(getDokumenteSilageergebnisse.type, getDokumenteSilageergebnisseSaga);
    yield takeLatest(getRationsservices.type, getRationsservicesSaga);
    yield takeEvery(GET_WERKE_FOR_ARTIKEL, getWerkeForArtikelSaga);
    yield takeEvery(updateKfzKennzeichen.type, updateKfzKennzeichenSaga);
    yield takeEvery(deleteKfzKennzeichen.type, deleteKfzKennzeichenSaga);
    yield takeEvery(updateSpedition.type, updateSpeditionSaga);
    yield takeEvery(deleteSpedition.type, deleteSpeditionSaga);
    yield takeEvery(SET_CURRENT_WORKFLOW_STEP, workflowSaga);
    yield takeEvery(getKontrakteForArtikel.type, getKontrakteForArtikelSaga);

    // Admin Sagas:
    yield takeEvery(UPDATE_USER, updateUserSaga);
    yield takeEvery(UPDATE_PARTNER, updatePartnerSaga);
    yield takeEvery(DELETE_USER, deleteUserSaga);

    yield takeLatest(GET_USER, getUserSaga);
    yield takeLatest(GET_USER_DETAILS, getUserDetailsSaga);
    yield takeLatest(GET_PARTNER_FROM_DEBITOR_AFTER_DELETE, updateSelectedWarenEmpfaenger);
    yield takeLatest(GET_PARTNER, getPartnerSaga);
    yield takeLatest(GET_DEBITOR_PARTNER, getDebitorPartnerSaga);
    yield takeLatest(GET_PARTNER_FROM_DEBITOR, getWarenEmpfaengerFromDebitor);
    yield takeLatest(GET_PARTNER_LIST, getPartnerListSaga);
    yield takeLatest(GET_PARTNER_DETAILS, getPartnerDetailsSaga);
    yield takeLatest(SEARCH_INTERNE_ARTIKEL, searchInterneArtikelSaga);
    yield takeLatest(GET_PREISLISTENKENNZEICHEN, getPreislistenkennzeichenSaga);
    yield takeLatest(SET_PREISLISTENKENNZEICHEN, postPreislistenkennzeichenSaga);
}

export default rootSaga;
