import { BestellpositionArtikelLoseWareView } from '../../../types';
import tableStyles from '../../../ui-components/Table/Table.module.scss';
import styles from './LoseWareTable.module.scss';
import { TableHeaderCell, TableRow } from '../../../ui-components/Table';
import { selectIsDebitor, selectIsFarmleiter } from '../../../../store/Navigation.store';
import { useSelector } from 'react-redux';
import {
    BezeichnungEntry,
    MengeEntry,
    ModifikationsstatusEntry,
    PreisartEntry,
    SiloEntry,
    WerkeEntry,
    WunschterminEntry,
} from '../PropertyEntries';
import { selectIsBestellungExisting } from '../../../../store/Global.selectors';
import { WunschterminLabels } from '../../Wunschtermin/labels';

export type TableLoseWareReadonlyProps = Readonly<{
    bestellpositionen: BestellpositionArtikelLoseWareView[];
}>;

export const LoseWareTableReadonly = ({ bestellpositionen }: TableLoseWareReadonlyProps): JSX.Element => {
    const isDebitor = useSelector(selectIsDebitor);
    const isFarmleiter = useSelector(selectIsFarmleiter);
    const isExisting = useSelector(selectIsBestellungExisting);

    return (
        <table className={[tableStyles._table, styles._positionenTable].join(' ')}>
            <thead>
                <tr className={tableStyles._tableHeaderRow}>
                    {(isDebitor || isFarmleiter) && isExisting ? <TableHeaderCell>Status</TableHeaderCell> : null}
                    <TableHeaderCell text additionalStyles={[styles._artikelNrBezeichnung]}>
                        Artikel
                    </TableHeaderCell>
                    {isDebitor || isFarmleiter ? <TableHeaderCell text>Werk</TableHeaderCell> : null}
                    {isDebitor || isFarmleiter ? <TableHeaderCell text>Berechnungsart</TableHeaderCell> : null}
                    {(isDebitor || isFarmleiter) && isExisting ? (
                        <TableHeaderCell text>{WunschterminLabels.ABHOL_BZW_LIEFERTERMIN}</TableHeaderCell>
                    ) : null}
                    {(isDebitor || isFarmleiter) && isExisting ? (
                        <TableHeaderCell text>{WunschterminLabels.UHRZEIT}</TableHeaderCell>
                    ) : null}
                    <TableHeaderCell text>Silo</TableHeaderCell>
                    <TableHeaderCell number>Menge (t)</TableHeaderCell>
                </tr>
            </thead>
            <tbody>
                {bestellpositionen.map((bestellposition, index: number) => {
                    return (
                        <TableRow key={index}>
                            {(isDebitor || isFarmleiter) && isExisting ? (
                                <ModifikationsstatusEntry.Cell
                                    nummer={bestellposition.nummer}
                                    positionsnummer={bestellposition.positionsnummer}
                                />
                            ) : null}
                            <BezeichnungEntry.Cell bestellposition={bestellposition} readonly />
                            <WerkeEntry.Cell bestellposition={bestellposition} readonly />
                            {isDebitor || isFarmleiter ? <PreisartEntry.Cell bestellposition={bestellposition} readonly /> : null}
                            {(isDebitor || isFarmleiter) && isExisting ? (
                                <WunschterminEntry.DatumCell bestellposition={bestellposition} readonly />
                            ) : null}
                            {(isDebitor || isFarmleiter) && isExisting ? (
                                <WunschterminEntry.UhrzeitCell bestellposition={bestellposition} readonly />
                            ) : null}
                            <SiloEntry.Cell bestellposition={bestellposition} readonly />
                            <MengeEntry.Cell bestellposition={bestellposition} readonly />
                        </TableRow>
                    );
                })}
            </tbody>
        </table>
    );
};
