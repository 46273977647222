import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';
import { getData, postData } from '../shared/fetchApi';
import {
    GET_PARTNER_FAILED,
    GET_PARTNER_FROM_DEBITOR_SUCCESS,
    GET_PARTNER_SUCCEEDED,
    GetDebitorPartnerAction,
    GetPartnerAction,
    GetPartnerAfterDeleteAction,
    GetPartnerFromDebitor,
} from '../store/Partner.store';
import { UPDATE_WARENEMPFAENGER_LIST } from '../store/User.store';

export function* getPartnerSaga(action: GetPartnerAction): IterableIterator<PutEffect | CallEffect> {
    try {
        const partner = yield call(getData, 'admin/partners/search?q=' + action.searchQuery);
        yield put({ type: GET_PARTNER_SUCCEEDED, partner });
    } catch (e) {
        yield put({ type: GET_PARTNER_FAILED });
    }
}

export function* getDebitorPartnerSaga(action: GetDebitorPartnerAction): IterableIterator<PutEffect | CallEffect> {
    try {
        const partner = yield call(getData, 'admin/partners/search/debitor?q=' + action.searchQuery);
        yield put({ type: GET_PARTNER_SUCCEEDED, partner });
    } catch (e) {
        yield put({ type: GET_PARTNER_FAILED });
    }
}

export function* getWarenEmpfaengerFromDebitor(action: GetPartnerFromDebitor): IterableIterator<PutEffect | CallEffect> {
    try {
        const partner = yield call(postData, 'admin/partners/debitor/warenempfaenger?q=' + action.query, action.debitorNummern);
        yield put({ type: GET_PARTNER_FROM_DEBITOR_SUCCESS, warenEmpfaenger: partner });
    } catch (e) {
        yield put({ type: GET_PARTNER_FAILED });
    }
}

export function* updateSelectedWarenEmpfaenger(action: GetPartnerAfterDeleteAction): IterableIterator<PutEffect | CallEffect> {
    try {
        const partners = yield call(
            postData,
            'admin/partners/debitor/warenempfaenger?q=',
            action.partner.map((p) => p.partnerNummer)
        );
        console.log('sending request', partners);
        yield put({ type: UPDATE_WARENEMPFAENGER_LIST, partner: partners, debitor: action.partner });
    } catch (e) {
        // Dispatch a failure action
        yield put({ type: GET_PARTNER_FAILED });
    }
}
